const config = {
  
  basename: "",
  defaultPath: "/home",
  fontFamily: `'Manrope', sans-serif`,
  borderRadius: 12,
  env: process.env.REACT_APP_ENV,
  Ip: process.env.REACT_APP_API,
};

export default config;
