 
/**
 * Color intention that you want to used in your theme
 * @param {JsonObject} theme Theme customization object
 */

export default function themePalette(theme) {
  return {
    mode: theme?.customization?.navType,
    fontFamily: theme?.customization?.fontFamily,
    fontSize: theme?.customization?.fontSize,
    
    common: {
      black: theme.colors?.darkPaper
    },
    primary: {
      light: theme.colors?.primaryLight,
      main: theme.colors?.primaryMain,
      dark: theme.colors?.primaryDark,
      200: theme.colors?.primary200,
      800: theme.colors?.primary800
    },
    secondary: {
      light: theme.colors?.secondaryLight,
      main: theme.colors?.secondaryMain,
      dark: theme.colors?.secondaryDark,
      200: theme.colors?.secondary200,
      800: theme.colors?.secondary800
    },
    error: {
      light: theme.colors?.errorLight,
      main: theme.colors?.errorMain,
      dark: theme.colors?.errorDark
    },
    orange: {
      light: theme.colors?.orangeLight,
      main: theme.colors?.orangeMain,
      dark: theme.colors?.orangeDark
    },
    warning: {
      light: theme.colors?.warningLight,
      main: theme.colors?.warningMain,
      dark: theme.colors?.warningDark
    },
    success: {
      light: theme.colors?.successLight,
      200: theme.colors?.success200,
      main: theme.colors?.successMain,
      dark: theme.colors?.successDark
    },
    grey: {
      grey50: theme.colors?.grey50,
      grey100: theme.colors?.grey100,
      darkTextSecondary: theme.darkTextSecondary,
      heading: theme.heading,
      darkTextPrimary: theme.darkTextPrimary,
      textDark: theme.textDark,
      grey50: theme.colors?.grey50,
      grey100: theme.colors?.grey100,
      darkTextSecondary: theme.darkTextSecondary,
      heading: theme.heading,
      darkTextPrimary: theme.darkTextPrimary,
      textDark: theme.textDark
    },
    dark: {
      light: theme.colors?.darkTextPrimary,
      main: theme.colors?.darkLevel1,
      dark: theme.colors?.darkLevel2,
      800: theme.colors?.darkBackground,
      900: theme.colors?.darkPaper
    },
    text: {
      primary: theme.darkTextPrimary,
      secondary: theme.darkTextSecondary,
      dark: theme.textDark,
      hint: theme.colors?.grey100
    },
    background: {
      paper: theme.paper,
      default: theme.backgroundDefault
    },
    color: {
      // button
      transparent: 'transparent',
      // ListView
      listHead2: '#6E6E6E',
      ListCell2: '#6E6E6E',
      //profile
      textHeadText:'#121926',
      textHead:'#898989',
    },
    indexpage:{
      boxshdow:theme.colors.boxshadow,
      statsbox:theme.colors.statsbox,
      statsbox1:theme.colors.statsbox1,
      viewbtn:theme.colors.viewbtn,
      fileupload:theme.colors.fileupload,
      navbartxt:theme.colors.navbartxt,
      statusColor:theme.colors.statusColor,
      modalcontent:theme.colors.modalcontent,
      modalcontent1:theme.colors.modalcontent1,
      modalresult:theme.colors.modalresult,
      closeIcon:theme.colors.closeIcon,
      undelinecolor:theme.colors.undelinecolor,
      cancelbtn:theme.colors.cancelbtn,
      gradientColor:theme.colors.gradientColor,
      attachbtn:theme.colors.attachbtn,
      cmntxt:theme.colors.cmntxt,
      cmntxt1:theme.colors.cmntxt1,
      borderCrl:theme.colors.borderCrl,
      personImg:theme.colors.personImg,
      patientdetail:theme.colors.patientdetail,
      personCaps:theme.colors.personCaps,
      personCaps1:theme.colors.personCaps1,
      backbutton:theme.colors.backbutton,
      boxwhite:theme.colors.boxwhite,
      pdftxt:theme.colors.pdftxt,
      cancelbtnCrl:theme.colors.cancelbtnCrl,
      cancelbtnPhoto:theme.colors.cancelbtnPhoto,
      headInputSecCrl:theme.colors.headInputSecCrl,
      statusboxCrl:theme.colors.statusboxCrl,
      subheadCrl:theme.colors.subheadCrl,
      backgroundTxt:theme.colors.backgroundTxt,
      borderTxt:theme.colors.borderTxt,
      bordeBottmcrl:theme.colors.bordeBottmcrl,
      uploadborder:theme.colors.uploadborder,
      dragspan:theme.colors.dragspan,
      statuboxCrl:theme.colors.statuboxCrl,
      statusboxcrl1:theme.colors.statusboxcrl1,
      lightgrayNew:theme.colors.lightgrayNew,
      checkboxblue:theme.colors.checkboxblue,
      primarywhite:theme.colors.primarywhite,
      loginCrl:theme.colors.loginCrl,
      loginCrlnew:theme.colors.loginCrlnew,
      errorCrl:theme.colors.errorCrl,
      updateWhite:theme.colors.updateWhite,
      checkbox:theme.colors.checkbox,
      tablecllcrl:theme.colors.tablecllcrl
    }
  };
}