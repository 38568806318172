// import { useDispatch, useSelector } from "react-redux";
// import { Outlet } from "react-router-dom";
// import { styled, useTheme } from "@mui/material/styles";
// import {
//   AppBar,
//   Box,
//   CssBaseline,
//   Toolbar,
//   useMediaQuery,
// } from "@mui/material";
// import Header from "./Header";
// import Sidebar from "./Sidebar";
// import Customization from "../Customization";
// import { drawerWidth } from "store/constant";
// import { SET_MENU } from "store/actions";

// const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
//   ({ theme, open }) => ({
//     ...theme.typography.mainContent,
//     borderRadius: 0,
//     marginTop: "40px",
//     paddingLeft: "10px",
//     paddingRight: "10px",
//     marginRight: "0px",
//     transition: theme.transitions.create(
//       "margin",
//       open
//         ? {
//             easing: theme.transitions.easing.easeOut,
//             duration: theme.transitions.duration.enteringScreen,
//           }
//         : {
//             easing: theme.transitions.easing.sharp,
//             duration: theme.transitions.duration.leavingScreen,
//           }
//     ),
//     [theme.breakpoints.up("md")]: {
//       marginLeft: open ? 0 : -(drawerWidth - 20),
//       width: `calc(100% - ${drawerWidth}px)`,
//     },
//     [theme.breakpoints.down("md")]: {
//       marginLeft: "20px",
//       width: `calc(100% - ${drawerWidth}px)`,
//       padding: "10px",
//     },
//     [theme.breakpoints.down("sm")]: {
//       marginLeft: "10px",
//       width: `calc(100% - ${drawerWidth}px)`,
//       padding: "10px",
//       marginRight: "10px",
//     },
//   })
// );

// // ==============================|| MAIN LAYOUT ||============================== //

// const MainLayout = () => {
//   const theme = useTheme();
//   const matchDownMd = useMediaQuery(theme.breakpoints.down("md"));
//   // Handle left drawer
//   const leftDrawerOpened = useSelector((state) => state.customization.opened);


//   const dispatch = useDispatch();

//   const handleLeftDrawerToggle = () => {
//     dispatch({ type: SET_MENU, opened: !leftDrawerOpened });
//   };

//   return (
//     <Box sx={{ display: "flex" }}>
//       <CssBaseline />
//       {/* header */}
//       <AppBar
//         enableColorOnDark
//         position="fixed"
//         color="inherit"
//         elevation={0}
//         sx={{
//           bgcolor: theme.palette.background.default,
//           transition: leftDrawerOpened
//             ? theme.transitions.create("width")
//             : "none",
//         }}
//       >
//         <Toolbar sx={{ padding: "0px" }}>
//           <Header handleLeftDrawerToggle={handleLeftDrawerToggle} />
//         </Toolbar>
//       </AppBar>

//       {/* drawer */}
//       <Sidebar
//         drawerOpen={!matchDownMd ? leftDrawerOpened : !leftDrawerOpened}
//         drawerToggle={handleLeftDrawerToggle}
//       />

//       {/* main content */}
//       <Main theme={theme} open={leftDrawerOpened}>
//         <Outlet />
//       </Main>
//       <Customization />
//     </Box>
//   );
// };

// export default MainLayout;




import { useDispatch, useSelector } from "react-redux";
import { Outlet } from "react-router-dom";
import { styled, useTheme } from "@mui/material/styles";
import {
  AppBar,
  Box,
  CssBaseline,
  Toolbar,
} from "@mui/material";
import Header from "./Header";
import Sidebar from "./Sidebar";
import Customization from "../Customization";
import { drawerWidth } from "store/constant";
import { SET_MENU } from "store/actions";

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
  ({ theme, open }) => ({
    ...theme.typography.mainContent,
    borderRadius: 0,
    marginTop: "40px",
    paddingLeft: "10px",
    paddingRight: "10px",
    marginRight: "0px",
    transition: theme.transitions.create(
      "margin",
      open
        ? {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
          }
        : {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
          }
    ),
    [theme.breakpoints.up("md")]: {
      marginLeft: open ? 0 : -(drawerWidth - 20),
      width: `calc(100% - ${drawerWidth}px)`,
    },
    [theme.breakpoints.down("md")]: {
      marginLeft: "20px",
      width: `calc(100% - ${drawerWidth}px)`,
      padding: "10px",
    },
    [theme.breakpoints.down("sm")]: {
      marginLeft: "10px",
      width: `calc(100% - ${drawerWidth}px)`,
      padding: "10px",
      marginRight: "10px",
    },
  })
);

// ==============================|| MAIN LAYOUT ||============================== //

const MainLayout = () => {
  const theme = useTheme();
  // Handle left drawer
  const leftDrawerOpened = useSelector((state) => state.customization.opened);

  const dispatch = useDispatch();

  const handleLeftDrawerToggle = () => {
    dispatch({ type: SET_MENU, opened: !leftDrawerOpened });
  };

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      {/* header */}
      <AppBar
        enableColorOnDark
        position="fixed"
        color="inherit"
        elevation={0}
        sx={{
          bgcolor: theme.palette.background.default,
          transition: leftDrawerOpened
            ? theme.transitions.create("width")
            : "none",
        }}
      >
        <Toolbar sx={{ padding: "0px" }}>
          <Header handleLeftDrawerToggle={handleLeftDrawerToggle} />
        </Toolbar>
      </AppBar>

      {/* drawer */}
      <Sidebar
        drawerOpen={leftDrawerOpened}
        drawerToggle={handleLeftDrawerToggle}
      />

      {/* main content */}
      <Main theme={theme} open={leftDrawerOpened}>
        <Outlet />
      </Main>
      <Customization />
    </Box>
  );
};

export default MainLayout;
