import { lazy } from "react";
 import Loadable from "ui-component/Loadable";
import MainLayout from "layout/MainLayout";
 const DashboardDefault = Loadable(lazy(() => import("screens/home/index")));
const Registration = Loadable(lazy(() => import("screens/registration/index")));
const MedicalExamination = Loadable(
  lazy(() => import("screens/MedicalExamination/Components/General"))
);
const MedicalExaminationVisualacuity = Loadable(
  lazy(() => import("screens/MedicalExamination/Components/VisualAcquity"))
);
const MedicalExaminationHearing = Loadable(
  lazy(() => import("screens/MedicalExamination/Components/Hearing"))
);

const SystemExamination = Loadable(
  lazy(() => import("screens/SystemExamination/index"))
);
const LabExaminationBiochemisty = Loadable(
  lazy(() => import("screens/LabExamination/Biochemistry"))
);
const LabExaminationBlood = Loadable(
  lazy(() => import("screens/LabExamination/Blood"))
);
const LabExaminationXray = Loadable(
  lazy(() => import("screens/LabExamination/Xray"))
);
const LabExaminationSerology = Loadable(
  lazy(() => import("screens/LabExamination/Serology"))
);
// const LabExaminationVaccination = Loadable(
//   lazy(() => import("screens/LabExamination/Vaccination"))
// );
const LabExaminationUrine = Loadable(
  lazy(() => import("screens/LabExamination/Urine"))
);

const BloodResult = Loadable(
  lazy(() => import("screens/ResultApproval/BloodResult"))
);
const BiochemistryResult = Loadable(
  lazy(() => import("screens/ResultApproval/BiochemistryResult"))
);
const SerologyResult = Loadable(
  lazy(() => import("screens/ResultApproval/SerologyResult"))
);
const UrineResult = Loadable(
  lazy(() => import("screens/ResultApproval/UrineResult"))
);
 
const SampleCollection = Loadable(
  lazy(() => import("screens/ResultApproval/SampleCollection"))
);

const XRay = Loadable(
  lazy(() => import("screens/ResultApproval/XRay"))
);
const RegistrationDetails = Loadable(
  lazy(() => import("screens/registration/Components/addNewRegister"))
);
const Billing = Loadable(
  lazy(() => import("screens/registration/Components/visitAndBilling"))
);

const Vaccination = Loadable(
  lazy(() => import("screens/Vaccination/Components/Vaccination"))
);
// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
  path: "/",
  element: (
    <>
      <MainLayout />
    </>
  ),
  children: [
   
    {
      path: "/home",
      element: <DashboardDefault />,
     
    },
    {
      path: "/registration",
      element: <Registration />,
    },
    {
      path: "/registration-Details",
      element: <RegistrationDetails />,
    },

    // {
    //   path: "/new-registr",
    //   element: <RegistrationDetails new={true} />,
    // },



    {
      path: "/billing",
      element: <Billing />,
    },
    {
      path: "/general",
      element: <MedicalExamination />,
    },
    {
      path: "/visualaquity",
      element: <MedicalExaminationVisualacuity />,
    },
    {
      path: "/hearing",
      element: <MedicalExaminationHearing />,
    },
    {
      path: "/systemexamination",
      element: <SystemExamination />,
    },
    {
      path: "/biochemistry",
      element: <LabExaminationBiochemisty />,
    },
    {
      path: "/blood",
      element: <LabExaminationBlood />,
    },
    {
      path: "/xray",
      element: <LabExaminationXray />,
    },
    // {
    //   path: "/vaccination",
    //   element: <LabExaminationVaccination />,
    // },
    {
      path: "/serology",
      element: <LabExaminationSerology />,
    },
    {
      path: "/urine",
      element: <LabExaminationUrine />,
    },
    {
      path: "/blood-result",
      element: <BloodResult />,
    },
    {
      path: "/biochemistry-result",
      element: <BiochemistryResult />,
    },
    {
      path: "/serology-result",
      element: <SerologyResult />,
    },
    {
      path: "/urine-result",
      element: <UrineResult />,
    },
    {
      path: "/sample-collection",
      element: <SampleCollection />
    },
    {
      path: "/X-Ray",
      element: <XRay />
    },
    {
      path: "/vaccination",
      element: <Vaccination />,
    },
   
  ],
};

export default MainRoutes;
