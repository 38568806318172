import { Avatar,IconButton} from '@mui/material';
import { NavLink } from 'react-router-dom'; // Import NavLink
import User from "assets/images/icons/user.svg";
import Refresh from "assets/images/icons/refresh .svg";
import Notification from "assets/images/icons/notification.svg";

const ProfileSection = () => {
  return (
    <>
      <IconButton
        sx={{
          transition: 'transform 0.3s',
          '&:hover': {
            transform: 'rotate(180deg)',
            background: '#fff',
          },
        }}
      >
        <Avatar
          src={Refresh}
          sx={{
            background: '#fff',
            height: '30px',
            width: '30px',
          }}
        />
      </IconButton>

      <IconButton
        sx={{
          transition: 'transform 0.3s',
          '&:hover': {
            transform: 'rotate(-45deg)',
            background: '#fff',
          },
        }}
      >
        <Avatar
          src={Notification}
          sx={{
            background: '#fff',
            height: '30px',
            width: '30px',
          }}
        />
      </IconButton>

      <NavLink to="/profile" style={{ textDecoration: 'none' }}>
        <IconButton sx={{ '&:hover': { background: '#fff' } }}>
          <Avatar
            src={User}
            sx={{ background: '#fff', height: '30px', width: '30px' }}
          />
        </IconButton>
      </NavLink>
    </>
  );
};

export default ProfileSection;