import adminMenu from "./dashboard";


// ==============================|| MENU ITEMS ||============================== //

const menuItems = {
  // admin: [
  //   adminMenu.admin,
  // ],
    admin: [
    
   ],
};

export default menuItems;
