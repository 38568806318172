import {
  IconUserPlus,
  IconHome,
  IconMicroscope,
  IconTestPipe,
  IconCheckupList,
} from "@tabler/icons";
import { ReactComponent as Home } from "assets/images/icons/home.svg";
import { ReactComponent as UserPen } from "assets/images/icons/user-pen.svg";
import { ReactComponent as Tube } from "assets/images/icons/tube.svg";
import { ReactComponent as Celestron } from "assets/images/icons/celestron.svg";
import { ReactComponent as UserEdit } from "assets/images/icons/user-edit.svg";

const icons = {
  IconUserPlus,
  IconHome,
  IconMicroscope,
  IconTestPipe,
  IconCheckupList,
  UserPen,
  Home,
  Tube,
  Celestron,
  UserEdit,
};

// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const admin = {
  id: "dashboard",
  type: "group",
  id: "dashboard",
  type: "group",
  children: [
    {
      id: "home",
      title: "Home",
      type: "item",
      url: "/home",
      icon: icons.IconHome,
      breadcrumbs: true,
    },
    {
      id: "registration",
      title: "Registration",
      type: "item",
      url: "/registration",
      icon: () => <UserEdit width={20} height={20} />,
      breadcrumbs: true,
    },
    {
      id: "medical-examination",
      title: "Medical Examination",
      type: "collapse",
      icon: icons.IconMicroscope,
      children: [
        {
          id: "general",
          title: "General",
          type: "item",
          url: "/registration",
          breadcrumbs: true,
          child: true,
        },
        {
          id: "visualaquity",
          title: "Visual Acuity",
          type: "item",
          url: "/registration",
          breadcrumbs: true,
          child: true,
        },
        {
          id: "hearing",
          title: "Hearing",
          type: "item",
          url: "/registration",
          breadcrumbs: true,
          child: true,
        },
      ],
    },
    {
      id: "systemexamination",
      title: "System  Examination",
      type: "item",
      url: "/registration",
      icon: icons.IconMicroscope,
      breadcrumbs: true,
    },
    {
      id: "lab-examination",
      title: "Lab Examination",
      type: "collapse",
      icon: icons.IconTestPipe,
      children: [
        {
          id: "blood",
          title: "Blood",
          type: "item",
          url: "/registration",
          breadcrumbs: true,
          child: true,
        },
        {
          id: "biochemistry",
          title: "Biochemistry",
          type: "item",
          url: "/registration",
          breadcrumbs: true,
          child: true,
        },
        {
          id: "serology",
          title: "Serology",
          type: "item",
          url: "/registration",
          breadcrumbs: true,
          child: true,
        },
        {
          id: "urine",
          title: "Urine & Stool",
          type: "item",
          url: "/registration",
          breadcrumbs: true,
          child: true,
        },
        {
          id: "xray",
          title: "X-ray",
          type: "item",
          url: "/registration",
          breadcrumbs: true,
          child: true,
        },
      ],
    },
    {
      id: "result-approval",
      title: "Result Approval",
      type: "collapse",
      icon: icons.IconCheckupList,
      children: [
        {
          id: "blood-result",
          title: "Blood",
          type: "item",
          url: "/registration",
          breadcrumbs: true,
          child: true,
        },
        {
          id: "biochemistry-result",
          title: "Biochemistry",
          type: "item",
          url: "/registration",
          breadcrumbs: true,
          child: true,
        },
        {
          id: "serology-result",
          title: "Serology",
          type: "item",
          url: "/registration",
          breadcrumbs: true,
          child: true,
        },
        {
          id: "x-ray",
          title: "X-Ray",
          type: "item",
          url: "/X-Ray",
          breadcrumbs: true,
          child: true,
        },
        {
          id: "urine-result",
          title: "Urine & Stool",
          type: "item",
          url: "/registration",
          breadcrumbs: true,
          child: true,
        },
        {
          id: "sample-collection",
          title: "Sample Collection",
          type: "item",
          url: "/sample-collection",
          breadcrumbs: true,
          child: true,
        },
      ],
    },
    {
      id: "vaccination",
      title: "Vaccination",
      type: "item",
      url: "/registration",
      icon: icons.IconMicroscope,
      breadcrumbs: true,
    },
  ],
};

const adminMenu = {
  admin,
};

export default adminMenu;
