import { createSlice } from '@reduxjs/toolkit';

const loginSlice = createSlice({
  name: 'login',
  initialState: {
    userData: {},
    data: {},
    // user: {},
    userDatas: {},
    datas: {},
    changePass: {},
    loading: false,
    error: null
},
  reducers: {
    userLogin: (state) => {
      state.loading = true;
      state.error = null;
    },
    loginSuccess: (state, action) => {
      state.loading = false;
      state.data = action.payload;
    },
    loginFail: (state, action) => {
      state.loading = false;
      state.error = action.payload;
   
    },
    loginUser: (state) => {
      state.loading = true;
      state.error = null;
    },
    loginUserSuccess: (state, action) => {
      state.loading = false;
      state.user = action.payload;
    },
    loginUserFail: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },


    SendOtpRequest: (state) => {
      state.loading = true;
      state.error = null;
    },
    sendOtpRequestSuccess: (state, action) => {
      state.loading = false;
      state.userDatas = action.payload;
    },
    sendOtpRequestFail: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    
    checkOtpRequest: (state) => {
      state.loading = true;
      state.error = null;
    },
    checkOtpRequestSuccess: (state, action) => {
      state.loading = false;
      state.datas = action.payload;
    },
    checkOtpRequestFail: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    changePasswordRequest: (state) => {
      state.loading = true;
      state.error = null;
    },
    changePasswordRequestSuccess: (state, action) => {
      state.loading = false;
      state.changePass = action.payload;
    },
    changePasswordRequestFail: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    }
  
  }
});

export const {
  userLogin,
  loginSuccess,
  loginFail,
  loginUser,
  loginUserSuccess,
  loginUserFail,
  SendOtpRequest,
  sendOtpRequestSuccess,
  sendOtpRequestFail,
  checkOtpRequest,
  checkOtpRequestSuccess,
  checkOtpRequestFail,
  changePasswordRequest,
  changePasswordRequestSuccess,
  changePasswordRequestFail
} = loginSlice.actions;
export const selectError = (state) => state.login.error;

export default loginSlice.reducer;
