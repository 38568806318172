const style = (theme) => ({
  AddNewMainGrid: {
    "&.MuiGrid-root": {},
  },
  hellotxt: {
    "&.MuiTypography-root": {
      color: "red",
    },
  },
  headbox: {
    "&.MuiBox-root": {
      width: "100%",
      boxShadow: `2px 2px 2px 2px ${theme.palette.indexpage.boxshdow}`,
      backgroundColor: theme.palette.background.paper,
      borderRadius: "8px",
      padding: "15px",
    },
  },
  headbox1: {
    "&.MuiBox-root": {
      width: "100%",
      boxShadow: `3px 3px 4px 3px ${theme.palette.indexpage.boxshdow}`,
      backgroundColor: theme.palette.background.paper,
      borderRadius: "8px",
      padding: "15px",
    },
  },
  headboxfile: {
    "&.MuiBox-root": {
      width: "100%",
      boxShadow: `2px 2px 2px 2px ${theme.palette.indexpage.boxshdow}`,
      backgroundColor: theme.palette.background.paper,
      borderRadius: "8px",
      padding: "15px",
      marginBottom: "6px",
    },
  },
  statusBox: {
    "&.MuiBox-root": {
      display: "flex",
      justifyContent: "space-between",
      marginTop: "20px",
    },
  },
  documenttxt: {
    "&.MuiBox-root": {},
  },
  statusBox1: {
    "&.MuiBox-root": {
      backgroundColor: theme.palette.indexpage.statsbox,
      border: `1px solid ${theme.palette.indexpage.statsbox1}`,
      borderRadius: "5px",
      color: theme.palette.indexpage.statsbox1,
      textAlign: "center",
      padding: "4px 7px 4px 7px",
      background: theme.palette.indexpage.statuboxCrl,
      fontFamily: theme.palette.fontFamily,
    },
  },
  buttonbox: {
    "&.MuiBox-root": {
      marginTop: "15px",
      display: "flex",
      justifyContent: "center",
    },
  },
  buttonbox1: {
    "&.MuiBox-root": {
      marginTop: "15px",
      display: "flex",
      justifyContent: "center",
    },
  },
  viewButton: {
    "&.MuiButtonBase-root": {
      width: "100%",
      height: "32px",
      backgroundColor: theme.palette.indexpage.viewbtn,
    },
  },
  drewerfileupload3: {
    "&.MuiBox-root": {
      borderColor: theme.palette.indexpage.fileupload,
      cursor: "pointer",
      height: "85px",
      opacity: ".7",
      backgroundColor: theme.palette.grey.grey50,
      backgroundImage:
        "url(\"data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%23aaa' stroke-width='2' stroke-dasharray='1.8%25%2c 1.3%25' stroke-dashoffset='7' stroke-linecap='butt'/%3e%3c/svg%3e\")",
      borderRadius: "3px",
      marginTop: "13px",
      padding: "5px",
    },
  },
  mbtypo: {
    "&.MuiTypography-root": {
      paddingRight: "10px",
      paddingTop: "10px",
      fontWeight: "500",
      opacity: ".8",
      fontSize: "15px",
      color: theme.palette.indexpage.fileupload,
    },
  },
  drewerfileupload1: {
    "&.MuiBox-root": {
      textAlign: "center",
      justifyContent: "center",
      alignItems: "center",
      display: "flex",
      flexDirection: "column",
    },
  },
  eqins5: {
    "&.MuiInputBase-input": {
      border: "none",
    },
    '& input[type="file"] .variant': {
      border: "none",
      borderRadius: 0,
    },
    '& input[type="file"]': {
      overflow: "hidden",
      width: "154px",
      paddingTop: "2px",

      paddingBottom: "6px",
      textAlign: "center",
      fontSize: "15px",
      borderBottom: "none",
    },
    '& input[type="file"].fieldset': {
      border: "none",
    },
    '& input[type="file"]::placeholder': {
      opacity: 0,
    },
    '& input[type="file"]::-webkit-file-upload-button': {
      visibility: "hidden",
    },
    '& input[type="file"]::before': {
      content: '"Drag your files or Browse"',
      display: "inline-block",
      color: "black",
      opacity: ".7",
      paddingBottom: "0px",
      cursor: "pointer",
      lineHeight: "13px",
    },
    '& input[type="file"]::after': {
      content: '""',
      display: "none",
    },
    '& input[type="file"]:valid + label::before': {
      content: '""',
      display: "none",
    },
    '& input[type="file"]:not(:valid) + label::before': {
      content: '""',
      display: "none",
    },
  },
  captionicon: {
    "&.MuiSvgIcon-root": {
      color: "red",
      height: "20px",
      width: "20px",
    },
  },
  navbarHeadtxt: {
    "&.MuiTypography-root": {
      color: theme.palette.indexpage.navbartxt,
      fontSize: "14px",
      fontFamily: theme.palette.fontFamily,
    },
  },
  cardStats: {
    "&.MuiTypography-root": {
      color: theme.palette.indexpage.statusColor,
      marginTop: "8px",
      fontFamily: theme.palette.fontFamily,
    },
  },
  cameraIcon: {
    "&.MuiSvgIcon-root": {
      marginRight: "3px",
      height: "19px",
    },
  },
  fileBox: {
    "&.MuiBox-root": {
      display: "flex",
      justifyContent: "space-between",
    },
  },

  headerInputSec1: {
    "&.MuiTypography-root": {
      marginTop: "1px",
      fontSize: "12px",
      fontFamily: theme.palette.fontFamily,
      color: theme.palette.indexpage.viewbtn,
      fontWeight: 700,
      textTransform: "uppercase",
      cursor: "pointer",
    },
  },
  mbBox: {
    "&.MuiBox-root": {
      display: "flex",
      justifyContent: "end",
    },
  },
  attachMentIcon: {
    "&.MuiSvgIcon-root": {
      transform: "rotate(-30deg)",
      color: theme.palette.indexpage.viewbtn,
      fontSize: "24px",
      marginTop: "5px",
    },
  },
  fileAttachbx: {
    "&.MuiBox-root": {
      textAlign: "center",
      display: "flex",
      justifyContent: "center",
    },
  },
  buttonGrid: {
    "&.MuiGrid-root": {
      marginTop: "10px",
      marginBottom: "0px",
      paddingTop: "0px",
    },
  },
  subHeadTypo: {
    "&.MuiTypography-root": {
      color: theme.palette.indexpage.subheadCrl,
      fontSize: "14px",
      marginBottom: "15px",
    },
  },

  activeCard: {
    "&.MuiBox-root": {
      display: "flex",
      alignItems: "center",
      border: `2px solid ${theme.palette.indexpage.headInputSecCrl}`,
      padding: "15px",
      borderRadius: "8px",
    },
  },
  inActiveCard: {
    "&.MuiBox-root": {
      display: "flex",
      alignItems: "center",
      border: `1px solid ${theme.palette.indexpage.subheadCrl}`,
      padding: "15px",
      borderRadius: "8px",
    },
  },
  visitBtnBox: {
    "&.MuiBox-root": {
      display: "flex",
      gap: 2,
      flexDirection: {
        xs: "column",
        sm: "row",
      },
      justifyContent: {
        xs: "flex-start",
        lg: "space-between",
      },
      "@media (max-width: 410px)": {
        flexDirection: "column",
      },
      "@media (min-width: 900px)": {
        justifyContent: "space-between",
        "& > :last-child": {
          marginLeft: "auto",
          marginRight: "auto",
        },
      },
    },
  },
  statusBox2: {
    "&.MuiBox-root": {
      backgroundColor: theme.palette.indexpage.statsbox,
      border: `1px solid ${theme.palette.indexpage.statusboxCrl} `,
      borderRadius: "5px",
      color: theme.palette.indexpage.statusboxCrl,
      textAlign: "center",
      padding: "4px 7px 4px 7px",
      background: theme.palette.indexpage.statusboxcrl1,
      fontFamily: theme.palette.fontFamily,
    },
  },

  activeTypoBill: {
    "&.MuiTypography-root": {
      color: theme.palette.indexpage.headInputSecCrl,
      fontFamily: theme.palette.fontFamily,
      fontWeight: 600,
      marginLeft: "10px",
    },
  },
  inActiveTypoBill: {
    "&.MuiTypography-root": {
      color: theme.palette.indexpage.subheadCrl,
      fontFamily: theme.palette.fontFamily,
      fontWeight: 700,
      marginLeft: "10px",
    },
  },
  pdfImg: {
    height: "25px",
    width: "25px",
  },
  //==============================Filter Component=============================//

  TextField: {
    fontFamily: theme.palette.fontFamily,
    marginBottom: 2,
    "& .MuiOutlinedInput-root": {
      backgroundColor: theme.palette.indexpage.backgroundTxt,
      borderRadius: "4px",
      "& fieldset": {
        borderColor: theme.palette.indexpage.borderTxt,
        borderRadius: "4px",
      },
      "&:hover fieldset": {
        borderColor: theme.palette.indexpage.borderTxt,
      },
      "&.Mui-focused fieldset": {
        borderColor: theme.palette.indexpage.borderTxt,
      },
      "& input": {
        cursor: "pointer",
        height: "15px",
        backgroundColor: theme.palette.indexpage.backgroundTxt,
      },
    },
    "& .MuiInputBase-root": {
      backgroundColor: theme.palette.indexpage.backgroundTxt,
    },
  },
  SearchInput: {
    fontFamily: theme.palette.fontFamily,
    "& .MuiInputBase-root": {
      borderBottom: `1px solid  ${theme.palette.indexpage.bordeBottmcrl}`,
      "&:hover:not(.Mui-disabled):before": {
        borderBottom: `1px solid  ${theme.palette.indexpage.bordeBottmcrl}`,
      },
      "&:before": {
        borderBottom: `1px solid  ${theme.palette.indexpage.bordeBottmcrl}`,
      },
      "&:after": {
        borderBottom: `1px solid  ${theme.palette.indexpage.bordeBottmcrl}`,
      },
      paddingLeft: "12px",
    },
    "& .MuiInputAdornment-positionEnd": {
      marginLeft: 0,
    },
  },

  Checkbox: {
    fontFamily: theme.palette.fontFamily,
    padding: "0 0 0 24px",
    color: theme.palette.indexpage.lightgrayNew,
    "&.Mui-checked": {
      color: theme.palette.indexpage.checkboxblue,
    },
    "& .MuiSvgIcon-root": {
      fontSize: 18,
    },
  },

  ViewSelectOption: {
    fontFamily: theme.palette.fontFamily,
    backgroundColor: theme.palette.indexpage.headInputSecCrl,
    color: theme.palette.indexpage.boxwhite,
    borderRadius: "4px",
    padding: "2px 4px",
    display: "inline-block",
    marginLeft: "8px",
  },
  selectedOption: {
    fontSize: "10px",
    "@media (min-width: 650px)": {
      fontSize: "15px",
    },
  },
  FilterOptionBox: {
    fontFamily: theme.palette.fontFamily,
    border: `1px solid ${theme.palette.indexpage.lightgrayNew}`,
    borderRadius: 10,
    boxShadow: `-2px 2px 5px ${theme.palette.indexpage.filterCrl}`,
  },
  modelBox: {
    position: "absolute",
    height: "fit-content",
    backgroundColor: "white",
    width: "370px",
    margin: 2,
    top: "50%",
    left: "46%",
    transform: "translate(-50%, -50%)",
    boxShadow: 24,
    p: 4,
    borderRadius: 4,
    "@media (max-width:400px)": {
      left: "45%",
      width: "320px",
    },
    "@media (min-width: 650px)": {
      left: "50%",
      width: "550px",
    },
  },
  ClearFiltersButton: {
    color: theme.palette.indexpage.borderTxt,
    fontSize: "16px",
    fontFamily: theme.palette.fontFamily,
    "@media (max-width: 650px)": {
      fontSize: "12px",
    },
    "@media (max-width:400px)": {
      fontSize: "10px",
    },
  },
  CancelButton: {
    fontFamily: theme.palette.fontFamily,
    height: "40px",
    border: `1px solid ${theme.palette.indexpage.lightgrayNew}`,
    "@media (max-width: 650px)": {
      fontSize: "10px",
    },
    "@media (max-width:400px)": {
      height: "30px",
      padding: "5px",
      fontSize: "10px",
    },
  },
  filterApplyButton: {
    fontFamily: theme.palette.fontFamily,
    backgroundColor: theme.palette.indexpage.viewbtn,
    color: theme.palette.indexpage.boxwhite,
    height: "40px",
    "&:hover": {
      backgroundColor: theme.palette.indexpage.viewbtn,
    },
    "@media (max-width: 650px)": {
      fontSize: "10px",
    },
    "@media (max-width:400px)": {
      fontSize: "10px",
      height: "30px",
      padding: "5px",
      margin: 0,
    },
  },

  scrollBox: {
    maxHeight: "360px",
    overflow: "scroll",
    "&::-webkit-scrollbar": {
      display: "none",
    },
    msOverflowStyle: "none",
    scrollbarWidth: "none",
  },
  heading: {
    display: "flex",
    overflow: "hidden",
    justifyContent: "space-between",
    fontSize: "14px",
    position: "sticky",
    top: 0,
    backgroundColor: theme.palette.indexpage.primarywhite,
    zIndex: 1000,
    paddingBottom: "6px",
  },
  viewFilterList: {
    margin: 0,
    maxHeight: "120px",
    overflow: "scroll",
    "&::-webkit-scrollbar": {
      display: "none",
    },
    msOverflowStyle: "none",
    scrollbarWidth: "none",
  },
  ScrollTextField: {
    color: "black",
    maxWidth: "400px",
    overflowX: "scroll",
    whiteSpace: "nowrap",
    display: "inline-block",
    cursor: "pointer",
    "&::-webkit-scrollbar": {
      display: "none",
    },
    msOverflowStyle: "none",
    scrollbarWidth: "none",
    "@media (max-width: 650px)": {
      maxWidth: "220px",
    },
    "@media (max-width:400px)": {
      maxWidth: "170px",
    },
  },
  UploadImgBox: {
    "&.MuiBox-root": {
      width: "100%",
      marginTop: "10px",
      overflow: "hidden",
      border: `1px solid ${theme.palette.indexpage.uploadborder}`,
      borderRadius: "5px",
    },
  },
  pdfImgBox: {
    height: "25px",
    width: "25px",
  },
  headBoxMain: {
    "&.MuiBox-root": {
      marginTop: "10px",
    },
  },
  statusBoxMain: {
    "&.MuiBox-root": {
      marginTop: "1px",
    },
  },
  iframeBox: {
    "&.MuiBox-root": {
      width: "100%",
      height: "150px",
      transform: "scale(1.5)",
      transformOrigin: "top",
      position: "relative",
      cursor: "pointer",
    },
  },
  attachIconBox: {
    "&.MuiBox-root": {
      marginTop: "-10px",
    },
  },
  dragSpan: {
    color: theme.palette.indexpage.dragspan,
  },
  dragSpan1: {
    color: theme.palette.indexpage.headInputSecCrl,
  },
  imagePhoto: {
    width: "100%",
    height: "150px",
    display: "block",
    objectFit: "cover",
  },
  imagePhotoDocument: {
    width: "100%",
    height: "150px",
    display: "block",
    objectFit: "fill",
  },
  TabIndicator: {
    "& .MuiTabs-indicator": {
      backgroundColor: "#0c66e4",
    },
  },
  TabIndicatorNew: {
    color: "#626F86",
    "&.Mui-selected": { color: "#0c66e4" },
  },
  newVisittxt: {
    "&.MuiTypography-root": {
      marginLeft: "10px",
    },
  },
  repeatTxt: {
    "&.MuiTypography-root": {
      marginLeft: "10px",
    },
  },
  referencegrid: {
    "&.MuiGrid-root": {
      marginTop: "15px",
    },
  },
  dividerGenral: {
    "&.MuiDivider-root": {
      borderStyle: "dashed",
      marginTop: "6px",
      marginBottom: "0px",
    },
  },
  toolTipbox: {
    "&.MuiBox-root": {
      textOverflow: "ellipsis",
      overflow: "hidden",
      width: "100%",
      whiteSpace: "nowrap",
    },
  },
  dividerGeneral: {
    "&.MuiDivider-root": {
      borderStyle: "dashed",
      marginTop: "5px",
      marginBottom: "5px",
    },
  },
  visitMainGrid: {
    "&.MuiGrid-root": {
      marginTop: { xs: "27PX", sm: "0PX" },
    },
  },
  visitRefbox: {
    "&.MuiBox-root": {
      border: "1px solid #76839A",
      height: "100%",
      width: "fit-content",
      padding: "12px",
      borderRadius: "8px",
      display: "grid",
      justifyContent: "center",
      alignItems: "center",
    },
  },
  visitContain: {
    "&.MuiGrid-root": {
      marginTop: "15px",
    },
  },
  visitDivider: {
    "&.MuiDivider-root": {
      borderStyle: "dashed",
      marginTop: "15px",
      marginBottom: "15px",
    },
  },
  TabBox: {
    "&.MuiBox-root": {
      borderBottom: 1,
      borderColor: "divider",
    },
  },
  TabBoxmain: {
    "&.MuiBox-root": {
      width: "100%",
      marginBottom: "15px",
    },
  },
  errorBox: {
    "&.MuiBox-root": {
      marginTop: "10px",
      marginLeft: "5px",
    },
  },
  errorTxt: {
    "&.MuiTypography-root": {
      color: "red",
      fontSize: "10px",
    },
  },
  spanCrl: {
    color: "red",
  },
  repeatVisitGrid: {
    "&.MuiGrid-root": {
      paddingLeft: "16px",
      alignItems: "flex-start",
    },
  },
  indexTxt: {
    "&.MuiTypography-root": {
      color: "#A8A9AB",
      fontWeight: 600,
      fontSize: "14px",
    }
  },
  smallScreentxt: {
    "&.MuiTypography-root": {
      color: "#A8A9AB",
      fontWeight: 600,
      fontSize: "13px",
    }
  },
  fieldBtn: {
    "&.MuiButtonBase-root": {
      border: "1px solid #EB0000",
      color: "#EB0000",
      height: "30px",
      borderColor: "#EB0000",

    }
  },
  deleteBtnvisit: {
    "&.MuiSvgIcon-root": {
      height: "16px",
      width: "16px",
      color: "#EB0000",
      marginRight: "2px",
    }
  },
  erroshowBox: {
    "&.MuiBox-root": {
      marginTop: "10px", marginLeft: "20px"
    }
  },
  pleasetxt: {
    "&.MuiTypography-root": {
      color: "red", fontSize: "10px"
    }
  },
  Checkboxvisit: {
    "&.MuiCheckbox-colorPrimary.Mui-checked": {
      color: theme.palette.indexpage.checkbox,
    }
  },
  largeScreen: {
    paddingY: "0px",
    "@media (min-height: 633px) ": {
      paddingY: "4px",
    },
    "@media (min-height: 705px) ": {
      paddingY: "10px",
    },
    "@media (min-height: 900px) ": {
      paddingY: "20px",
    },
  },
  headerInputSec: {
    "&.MuiTypography-root": {
      marginTop: "3px",
      marginBottom: "10px",
      fontSize: "12px",
      fontFamily: theme.palette.fontFamily,
      color: theme.palette.indexpage.navbartxt,
      fontWeight: 700,
      textTransform: "uppercase",
      "@media (min-height: 633px) ": {
        paddingTop: "4px",
      },
      "@media (min-height: 705px) ": {
        paddingTop: "10px",
      },
      "@media (min-height: 900px) ": {
        paddingTop: "20px",
      },
    },
  },
  //==========================TableHeader====================================
  tableCell: {
    padding: "8px",
    position: { xs: "sticky", md: "sticky", sm: "sticky" },
    left: { sm: "0px" },
    top: 0,
    zIndex: 3,
    backgroundColor: theme.palette.indexpage.tablecllcrl,
  },
  no: {
    fontWeight: 700,
    fontSize: "12px",
    paddingRight: "23px"
  },
  siNumCell: {
    padding: "8px",
    position: { xs: "sticky", md: "sticky", sm: "sticky" },
    left: { sm: "50px" },
    top: 0,
    zIndex: 3,
    backgroundColor: "#dbdfe6",
  },
  refNumCell: {
    padding: "8px",
    position: { xs: "sticky", sm: "unset", md: "sticky", lg: "sticky" },
    left: { sm: "200px" },
    top: 0,
    zIndex: 3,
    backgroundColor: "#dbdfe6",
  },
  tableHeadCell: {
    padding: "8px",
    position: "sticky",
    top: 0,
    backgroundColor: "#dbdfe6",
    zIndex: 1,
  },
  LabReportCell: {
    padding: "8px",
    position: "sticky",
    top: 0,
    backgroundColor: "#dbdfe6",
    zIndex: 1,
    whiteSpace: "nowrap",
  },
  actionCell: {
    padding: "8px",
    backgroundColor: "#dbdfe6",
    zIndex: 1,
    position: { xs: "sticky", md: "sticky", sm: "sticky" },
    right: { sm: "0px" },
    top: 0,
    textAlign: "start",
  },
  tableHeadingName: {
    fontWeight: 700,
    fontSize: "12px"
  },

  //===================Registration Table============
  registrationTable: {
    position: "relative",
    height: "auto",
    display: "flex",
    flexDirection: "column",
    paddingLeft: "15px",
    paddingRight: "15px",
  },
  registrationContainer: {
    marginBottom: "0.5rem",
    display: "flex",
    justifyContent: "center",
  },
  datePicker: {
    display: "flex",
    alignItems: "flex-end",
    justifyContent: {
      xs: "center",
      sm: "flex-start",
      lg: "flex-end",
    },
    marginTop: { xs: "10px", sm: "0" },
  },
  newRegistration: {
    display: { xs: "flex", sm: "none" },
    justifyContent: "space-between",
    marginTop: "10px",
  },
  newRegistrationButton: {
    backgroundColor: "#0c66e4",
    color: "#fff",
    height: "32px",
    "&:hover": { backgroundColor: "#0c66e4" },
    width: "70%",
  },
  filterButton: {
    backgroundColor: "#0c66e4",
    color: "#fff",
    height: "32px",
    width: "32px",
    "&:hover": { backgroundColor: "#0c66e4" },
    padding: 0,
  },
  filterIcon: {
    fontSize: "19px"
  },
  desktopViewButton: {
    display: { xs: "none", sm: "flex" },
    alignItems: "center",
    justifyContent: "flex-end",
    marginTop: "16px",
  },
  viewButtonTable: {
    marginLeft: 0,
    backgroundColor: "#0c66e4",
    color: "#fff",
    width: "90px",
    height: "32px",
    "&:hover": { backgroundColor: "#0c66e4" },
    display: { xs: "none", sm: "flex" },
    justifyContent: "center",
    alignItems: "center",
    padding: "0 12px 0 4px",
    flexDirection: "row",
    "& .MuiSvgIcon-root": {
      marginRight: "4px",
    },
  },
  tableContainer: {
    flexGrow: 1,
    overflowX: "auto",
    overflowY: "auto",
    boxShadow: {
      xs: "none",
      sm: "none",
      md: "0px 4px 8px rgba(0, 0, 0, 0.2)",
    },
    width: "100%",
  },
  numRow: {
    minWidth: "50px",
    padding: "4px 8px",
    whiteSpace: "nowrap",
    overflow: "hidden",
    position: { sm: "sticky", md: "sticky" },
    left: 0,
    zIndex: 2,
    backgroundColor: "inherit",
  },
  refNum: {
    minWidth: "150px",
    padding: "4px 8px",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    position: { sm: "sticky", md: "sticky" },
    left: 50,
    zIndex: 2,
    backgroundColor: "inherit",
  },
  avatarRow: {
    minWidth: "200px",
    padding: "4px 8px",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    position: {
      sm: "unset",
      md: "sticky",
      lg: "sticky",
    },
    top: 0,
    left: 200,
    zIndex: 2,
    backgroundColor: "inherit",
  },
  avatarBox: {
    display: "flex",
    alignItems: "center",
    whiteSpace: "nowrap",
    minWidth: "150px",
  },
  avatar: {
    marginRight: 1,
    bgcolor: "#e0e0e0",
    color: "#808080",
    flexShrink: 0,
    width: "25px",
    height: "25px",
  },
  nameRow: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    fontSize: "13px",
    fontWeight: "400",
    color: "#172B4D",
  },
  passportNumberRow: {
    minWidth: "100px",
    padding: "4px 8px",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    backgroundColor: "inherit",
  },
  tableRow: {
    minWidth: "150px",
    padding: "4px 8px",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    backgroundColor: "inherit",
  },
  tableSmallRow: {
    minWidth: "50px",
    padding: "4px 8px",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    backgroundColor: "inherit",
  },
  actionButtonRow: {
    position: { sm: "sticky", md: "sticky" },
    padding: "4px 8px",
    right: { xs: "auto", sm: 0 },
    backgroundColor: "inherit",
    minWidth: "100px",
    textAlign: "right",
    whiteSpace: "nowrap",
    padding: "0px !important",
  },
  tableHeadBG: {
    backgroundColor: "#dbdfe6"
  },
  tableRowHight: {
    height: "5px"
  },
  tableBox: {
    display: "flex",
    justifyContent: "flex-end"
  },
  row: { padding: "16px" },
  paginationBox: {
    display: "flex",
    justifyContent: "center",
    width: "100%"
  },
  paginationBox1: {
    display: { xs: 'block', sm: 'flex' },
    paddingTop: '19px',
  },
  paginationSpan: {
    paddingRight: "10px",
    paddingTop: "12px",
    display: "inline-block",
    textAlign: "center"
  },
  paginationBox2: {
    alignItems: "center",
    display: {
      xs: "block",
      sm: "flex"
    }
  },
  RowsPerPage: {
    marginRight: "10px",
    paddingTop: "4px"
  },
  paginationTextField: {
    width: "50px",
    marginTop: "5px"
  },
  inputProp: {
    height: '30px',
    paddingRight: '10px'
  },
  changeIcon: {
    position: 'absolute',
    right: -5,
    top: -5,
    fontSize: '18px',
    color: 'black',
    cursor: 'pointer',
    zIndex: 3,
  },
  pagination: {
    ".MuiTablePagination-selectLabel, .MuiTablePagination-displayedRows":
    {
      margin: 0,
      marginRight: { xs: "5px", sm: 0 },
      marginLeft: { xs: "0px", sm: "10px" }
    },
    ".MuiTablePagination-toolbar": {
      display: "flex",
      justifyContent: {
        xs: "center",
      },
      padding: "0",
      paddingTop: {
        xs: "10px",
      },
      flexDirection: {
        xs: "column",
        sm: "row",
      },
      alignItems: {
        xs: "center",
      },
    },
    ".css-teccuw-MuiToolbar-root-MuiTablePagination-toolbar .MuiTablePagination-actions":
    {
      marginLeft: {
        xs: "0px !important",
        sm: "20px !important",
      },
      marginTop: {
        xs: "0px",
      },
    },
    ".css-vrgv39-MuiInputBase-root-MuiTablePagination-select": {
      marginRight: "0px",
      marginLeft: "0px",
    },
  }
});

export default style;
