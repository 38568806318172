// import { Box, Typography } from "@mui/material";
// import Grid from "@mui/material/Grid";

// const UserSection = () => {
//   const location = window?.location?.pathname.split("/");

//   return (
//     <Box sx={{ ml: 5 }}>
//       <Grid container>
//         <Grid container>
//           <Grid
//             item
//             xs={11}
//             style={{ display: "flex", justifyContent: "flex-start" }}
//           >
//             <Typography
//               variant="h3"
//               sx={{
//                 textAlign: "start",
//                 fontWeight: 450,
//                 textTransform: "uppercase",
//                 fontWeight: 700,
//                 color: "#172B4D",
//               }}
//             >
//               {location}
//             </Typography>
//           </Grid>
//         </Grid>
//       </Grid>
//     </Box>
//   );
// };

// export default UserSection;

// import React, { useEffect, useState, useMemo } from 'react';
// import { useLocation, useNavigate } from 'react-router-dom';
// import adminMenu from 'menu-items/dashboard';
// import { Box, Button, Grid, Typography } from '@mui/material';
// import ArrowBackIcon from '@mui/icons-material/ArrowBack';
// import styles from "screens/registration/style";
// import CmnStyles from "ui-component/common/style";
// import { useTheme } from "@mui/material/styles";

// const findMenuItemByUrl = (items, url) => {
//   for (const item of items) {
//     if (item.url === url && item.breadcrumbs) {
//       return { title: item.title };
//     }
//     if (item.children) {
//       const foundInChildren = findMenuItemByUrl(item.children, url);
//       if (foundInChildren) return foundInChildren;
//     }
//   }
//   return null;
// };

// const UserSection = () => {
//   const theme = useTheme();
//   const style = styles(theme);
//   const CmnStyle = CmnStyles(theme);

//   const location = useLocation();
//   const navigate = useNavigate();
//   const [currentTitle, setCurrentTitle] = useState('');

//   const menuItems = useMemo(() => adminMenu.admin.children, []);

//   useEffect(() => {
//     const result = findMenuItemByUrl(menuItems, location.pathname);
//     setCurrentTitle(result ? result.title : '');
//   }, [location, menuItems]);

//   const backClick = () => {
//     navigate('/home');
//   };

//   const showBackButton = currentTitle.toLowerCase() !== 'home';

//   return (
//     <Box sx={{ ml: 5 }}>
//       <Grid container>
//         {showBackButton && (
//           <Grid item md={1.5}>
//             <Button sx={CmnStyle.navBackButton} onClick={backClick}>
//               <ArrowBackIcon sx={CmnStyle.navArrowbackIcon} />
//               Back
//             </Button>
//           </Grid>
//         )}
//         <Grid
//           item
//           md={showBackButton ? 10.5 : 12}
//           sx={{
//             display: 'flex',
//             alignItems: 'center'
//           }}
//         >
//           <Typography
//             variant="h4"
//             sx={{
//               textAlign: 'start',
//               fontWeight: 650,
//               textTransform: 'uppercase'
//             }}
//           >
//             {currentTitle}
//           </Typography>
//         </Grid>
//       </Grid>
//     </Box>
//   );
// };

// export default UserSection;

import React, { useMemo } from "react";
import { Box, Typography, Button } from "@mui/material";
import Grid from "@mui/material/Grid";
import { useLocation, useNavigate } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import adminMenu from "menu-items/dashboard";
import CmnStyles from "ui-component/common/style";
import styles from "screens/registration/style";
import { useTheme } from "@mui/material/styles";

const findMenuItemByUrl = (items, url) => {
  for (const item of items) {
    if (item?.url === url) {
      return item;
    }
    if (item?.children) {
      const foundInChildren = findMenuItemByUrl(item?.children, url);
      if (foundInChildren) return foundInChildren;
    }
  }
  return null;
};

const UserSection = () => {
  const theme = useTheme();
  const style = styles(theme);
  const CmnStyle = CmnStyles(theme);

  const location = useLocation();
  const navigate = useNavigate();
  const menuItems = useMemo(() => adminMenu?.admin?.children, []);

  const currentMenuItem = useMemo(() => {
    return findMenuItemByUrl(menuItems, location?.pathname);
  }, [location?.pathname, menuItems]);

  const SecTitle = location?.pathname
    .replace(/\//g, " ")
    .replace(/-/g, " ")
    .toUpperCase();
  const adjustedSecTitle =
    location?.pathname === "/billing"
      ? location?.state?.billing === true
        ? "BILLING"
        : "REGISTRATION DETAILS"
      : location?.pathname === "/registration-Details" && location.state.mode === "add"
        ? "REGISTRATION"
        : SecTitle;

  const displayTitle =
    currentMenuItem && currentMenuItem?.breadcrumbs
      ? currentMenuItem?.title
      : adjustedSecTitle || null;

  const backClick = () => {
    if (location?.pathname === "/registration-Details") {
      navigate("/registration");
    } else if (location?.pathname === "/billing") {
      navigate("/registration");
    } else if (location?.pathname === "/hearing") {
      navigate("/visualaquity");
    } else if (location?.pathname === "/visualaquity") {
      navigate("/general");
    } else if (location?.pathname === "/general") {
      navigate("/registration");
    } else if (location?.pathname === "/systemexamination") {
      navigate("/hearing");
    }
    else if (location?.pathname === "/blood-result") {
      navigate("/urine");
    }
    else if (location?.pathname === "/biochemistry-result") {
      navigate("/blood-result");
    }
    else if (location?.pathname === "/serology-result") {
      navigate("/biochemistry-result");
    }
    else if (location?.pathname === "/urine-result") {
      navigate("/serology-result");
    }
    else if (location?.pathname === "/urine") {
      navigate("/serology");
    } else if (location?.pathname === "/serology") {
      navigate("/biochemistry");
    } else if (location?.pathname === "/biochemistry") {
      navigate("/blood");
    } else if (location?.pathname === "/blood") {
      navigate("/systemexamination");
    }   
    else {
      navigate("/home");
    }
  };

  const showBackButton = displayTitle && displayTitle?.toLowerCase() !== "home";

  return (
    <Box sx={{ ml: 5 }}>
      <Grid container>
        {showBackButton && (
          <Grid item md={1.5}>
            <Button sx={CmnStyle?.navBackButton} onClick={backClick}>
              <ArrowBackIcon sx={CmnStyle?.navArrowbackIcon} />
              Back
            </Button>
          </Grid>
        )}
        <Grid
          item
          md={showBackButton ? 10.5 : 12}
          sx={{
            display: "flex",
            alignItems: "center",
          }}
        >
          {displayTitle && (
            <Typography
              variant="h4"
              sx={{
                textAlign: "start",
                fontWeight: 650,
                textTransform: "uppercase",
              }}
            >
              {displayTitle}
            </Typography>
          )}
        </Grid>
      </Grid>
    </Box>
  );
};

export default UserSection;
