 
const styles = (theme) => ({

container: {
    marginTop: '0px',
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'space-between',
    '& .MuiGrid-root': {
      '& > .MuiGrid-item': {
        paddingTop: '0px',
      }
    }
  },
  searchGridItem: {
    marginTop: '0px',
    paddingTop: '0px',
  },
  searchTextField: {
    width: '100%',
    maxWidth: {
      xs: '100%',
      sm: '80%',
    },
    '& .MuiOutlinedInput-root': {
      height: '32px',
      '& fieldset': {
        borderRadius: 1,
      },
      '& input': {
        padding: '0 8px',
        height: '32px',
      },
    },
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    paddingTop: '0px',
    display: { xs: 'none', sm: 'flex' },
  },
  boxContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginRight: {
      lg: '-29.3',
    }
  },
  addButton: {
    backgroundColor: '#0c66e4',
    border: 'none',
    color: '#fff',
    height: '32px',
    minHeight: '32px',
    padding: '4px 12px',
    boxShadow: 'none',
    '&:hover': {
      backgroundColor: '#0c66e4',
    },
    display: { xs: 'none', sm: 'flex' }
  },

  
  commonModalContent: {
    "&.MuiBox-root": {
      position: "absolute",
      padding: "15px !important",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      boxShadow: 24,
      borderRadius: "11px",
      backgroundColor: theme.palette.indexpage.modalcontent,
      maxHeight: "100vh",
    },
  },
  modalBoxHeader: {
    "&.MuiGrid-root": {
      width: "100%",
      marginLeft: "0px",
    },
  },

  modalHeadContent: {
    "&.MuiBox-root": {
      padding: "15px 0px",
      fontSize: "20px",
      fontWeight: "bold",
      color: theme.palette.indexpage.modalcontent1,
      position: "relative",
      display: "flex",
      alignItems: "center",
    },
  },
  modalResult: {
    "&.MuiBox-root": {
      border: "1px solid darkgrey",
      height: "40px",
      display: "flex",
      alignItems: "center",
      gap: "5px",
      p: "10px",
      borderRadius: "5px",
      fontWeight: "500",
      color: theme.palette.indexpage.modalresult,
    },
  },
  tableHeading: {
    "&.MuiBox-root": {
      padding: "15px 0px",
      fontSize: "20px",
      fontWeight: "bold",
      color: "red",
    },
  },
  closeIconGrid: {
    "&.MuiGrid-root": {
      position: "relative",
      top: "12px",
    },
  },
  closeIcon: {
    "&.MuiSvgIcon-root": {
      color: theme.palette.indexpage.closeIcon,
      cursor: "pointer",
    },
  },
  headerUnderLine: {
    "&.MuiBox-root": {
      position: "relative",
      width: "96%",
      height: "1px",
      zIndex: "1",
      backgroundColor: theme.palette.indexpage.undelinecolor,
      margin: "auto",
    },
  },
  modalboxComponet: {
    "&.MuiBox-root": {
      height: "auto",
      maxHeight: "80vh",
      overflowY: "auto",
      padding: "15px",
      marginTop: "0px",
      borderRadius: 4,
      scrollbarWidth: "thin",
      "-ms-overflow-style": "none",
      "&::-webkit-scrollbar": {
        width: "0px",
        height: "0px",
        display: "none",
      },
      "&::-webkit-scrollbar-thumb": {
        backgroundColor: "rgba(0,0,0,0.5)",
        borderRadius: "8px",
      },
    },
  },
  modalboxComponet1: {
    "&.MuiBox-root": {
      height: "auto",
      overflowY: "auto",
      padding: "20px",
      marginTop: "0px",
      borderRadius: 4,
      maxHeight: "100vh",
    },
  },
  arrowBackIcon: {
    "&.MuiSvgIcon-root": {
      fontSize: "16px",
      color: theme.palette.indexpage.viewbtn,
      cursor: "pointer",
    },
  },
  commonBack: {
    "&.MuiTypography-root": {
      marginLeft: "19px",
      fontSize: "22px",
      color: theme.palette.indexpage.viewbtn,
      cursor: "pointer",
    },
  },
  cropButton: {
    backgroundColor: theme.palette.indexpage.viewbtn,
    marginBottom: "15px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "5px",
    height: "30px",
    "&:hover": {
      backgroundColor: theme.palette.indexpage.viewbtn,
    },
  },
  cropButton1: {
    backgroundColor: theme.palette.indexpage.viewbtn,
    marginBottom: "15px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "5px",
    width: {
      xs: "100%",
      sm: "100%",
      md: "auto",
    },
    height: "30px",
    "&:hover": {
      backgroundColor: theme.palette.indexpage.viewbtn,
    },
  },
  cropButtonIcon: {
    height: "15px",
  },
  cropTxt: {
    "&.MuiTypography-root": {
      marginRight: "5px",
    },
  },
  cropTxt1: {
    "&.MuiTypography-root": {},
  },
  cropButtonBox: {
    marginTop: "30px",
  },
  attachButton1: {
    "&.MuiButtonBase-root": {
      backgroundColor: theme.palette.indexpage.viewbtn,
      width: "100%",
      color: theme.palette.indexpage.attachbtn,
      borderRadius: "5px",

      "&:hover": {
        backgroundColor: theme.palette.indexpage.viewbtn,
      },
    },
  },
  attachButton2: {
    "&.MuiButtonBase-root": {
      backgroundColor: theme.palette.indexpage.viewbtn,
      width: "100%",
      color: theme.palette.indexpage.attachbtn,
      borderRadius: "5px",

      "&:hover": {
        backgroundColor: theme.palette.indexpage.viewbtn,
      },
    },
  },
  cancelButton1: {
    "&.MuiButtonBase-root": {
      border: `1px solid ${theme.palette.indexpage.cancelbtnCrl}`,
      width: { xs: "100%", sm: "100%" },
      borderColor: theme.palette.indexpage.cancelbtnCrl,
    },
  },
  cancelButtonPhoto: {
    "&.MuiButtonBase-root": {
      border: "1px solid",
      width: { xs: "100%", sm: "auto" },
      maxWidth: { xs: "100%", sm: "150px" },
      padding: { xs: "8px 16px", sm: "6px 12px" },
      borderColor: theme.palette.indexpage.cancelbtnPhoto,
      marginLeft: { xs: "0", sm: "10px" },
    },
  },
  attachButton3: {
    "&.MuiButtonBase-root": {
      backgroundColor: theme.palette.indexpage.viewbtn,
      width: "auto",
      color: theme.palette.indexpage.attachbtn,
      borderRadius: "5px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      padding: "8px 16px",
      "&:hover": {
        backgroundColor: theme.palette.indexpage.viewbtn,
      },
    },
  },
  attachButton4: {
    "&.MuiButtonBase-root": {
      backgroundColor: theme.palette.indexpage.viewbtn,
      width: "10%",
      color: theme.palette.indexpage.attachbtn,
      borderRadius: "5px",
      "&:hover": {
        backgroundColor: theme.palette.indexpage.viewbtn,
      },
    },
  },

  saveButton: {
    "&.MuiButtonBase-root": {
      backgroundColor: theme.palette.indexpage.viewbtn,
      color: theme.palette.background.paper,
      width: "100%",
      "&:hover": {
        backgroundColor: theme.palette.indexpage.viewbtn,
      },
    },
  },
  saveButton1: {
    "&.MuiButtonBase-root": {
      backgroundColor: theme.palette.indexpage.viewbtn,
      color: theme.palette.background.paper,
      width: "100%",
      marginLeft: "5px",
      "&:hover": {
        backgroundColor: theme.palette.indexpage.viewbtn,
      },
    },
  },
  saveButtonPhoto: {
    "&.MuiButtonBase-root": {
      backgroundColor: theme.palette.indexpage.viewbtn,
      color: theme.palette.background.paper,
      width: { xs: "100%", sm: "auto" },
      maxWidth: { xs: "100%", sm: "150px" },
      padding: { xs: "8px 16px", sm: "6px 12px" },
      "&:hover": {
        backgroundColor: theme.palette.indexpage.viewbtn,
      },
    },
  },
  cancelButton: {
    "&.MuiButtonBase-root": {
      border: "1px solid",
      width: "100%",
      borderColor: theme.palette.indexpage.cancelbtn,
    },
  },
  cancelButtonRemove: {
    "&.MuiButtonBase-root": {
      border: "1px solid",
      width: "100%",
      borderColor: theme.palette.indexpage.cancelbtnPhoto,
    },
  },
  cancelButton3: {
    "&.MuiButtonBase-root": {
      border: "1px solid",
      width: "50%",
      borderColor: theme.palette.indexpage.cancelbtn,
    },
  },
  imageMainBox: {
    "&.MuiBox-root": {
      borderRadius: "5px",
      boxShadow: `2px 2px 2px 2px ${theme.palette.indexpage.boxshdow}`,
    },
  },
  filecontainerBox: {
    "&.MuiBox-root": {
      borderRadius: "5px",
      boxShadow: `2px 2px 2px 2px ${theme.palette.indexpage.boxshdow} `,
      padding: "12px",
    },
  },
  contactImg: {
    borderRadius: "8px",
    width: "100%",
    height: "14rem",
    objectFit: "contain",
    objectPosition: "center",
    [theme.breakpoints.up("lg")]: { height: "100%" },
  },
  imageBox: {
    "&.MuiBox-root": {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      width: "100%",
      overflow: "hidden",
    },
  },
  imagePhoto: {
    "&.MuiBox-root": {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
  },
  contactPhoto: {
    borderRadius: "8px",
    width: "100%",
    height: "300px",
    objectFit: "contain",
  },
  contactImg1: {
    borderRadius: "8px",
    width: "100%",
    height: "auto",
    objectFit: "contain",
  },
  attachImgBtn: {
    "&.MuiButtonBase-root": {
      border: "1px solid",
      borderColor: theme.palette.indexpage.cancelbtn,
      width: "100%",
    },
  },
  attachTypo: {
    "&.MuiTypography-root": {
      whiteSpace: "nowrap",
      fontFamily: theme.palette.fontFamily,
      fontSize: "11px",
    },
  },
  cameraIcon: {
    "&.MuiSvgIcon-root": {
      fontSize: "15px",
    },
  },
  personImg: {
    borderRadius: "4px",
    width: "100%",
    height: "100%",
    objectFit: "cover",
    border: `4px solid ${theme.palette.indexpage.personImg}`,
  },
  personImg1: {
    borderRadius: "4px",
    width: "100%",
    height: "auto",
    objectFit: "cover",
    border: `4px solid ${theme.palette.indexpage.personImg}`,
  },
  imageGrid: {
    "&.MuiGrid-root": {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
  },
  fileAttachbtn: {
    "&.MuiGrid-root": {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
  },
  addIcon: {
    "&.MuiSvgIcon-root": {
      fontSize: "18px",
      marginTop: "0px",
    },
  },
  attachIcon: {
    "&.MuiSvgIcon-root": {
      transform: "rotate(-30deg)",
      fontSize: "18px",
      marginTop: "0px",
    },
  },
  fileImg: {
    height: "150px",
    width: "100%",
    cursor: "pointer",
  },
  readIcon: {
    "&.MuiSvgIcon-root": {
      marginRight: "3px",
      height: "15px",
    },
  },
  savebtn: {
    "&.MuiGrid-root": {
      marginTop: "10px",
    },
  },
  imgandbtnbox: {
    "&.MuiBox-root": {
      padding: "16px",
    },
  },
  passportBtnbox: {
    "&.MuiBox-root": {
      marginTop: "10px",
    },
  },
  submitbox: {
    "&.MuiGrid-root": {
      marginTop: "5px",
    },
  },
  fileBox1: {
    "&.MuiBox-root": {
      marginLeft: "10px",
    },
  },
  formLabel: {
    "&.MuiFormLabel-root": {
      color: theme.palette.indexpage.statusColor,
      fontSize: "12px",
      fontFamily: theme.palette.fontFamily,
    },
  },
  largeBtntxt: {
    "&.MuiTypography-root": {
      fontSize: "14px",
      fontFamily: theme.palette.fontFamily,
    },
  },
  largeBtntxt2: {
    "&.MuiTypography-root": {
      fontSize: { xs: "8px", md: "12px", sm: "8px", lg: "12px" },
      fontFamily: theme.palette.fontFamily,
      whiteSpace: "nowrap",
      [theme.breakpoints.down("md")]: {
        fontSize: "12px",
      },
    },
  },
  largeBtntxtRemove: {
    "&.MuiTypography-root": {
      fontSize: { xs: "8px", md: "12px", sm: "8px", lg: "12px" },
      fontFamily: theme.palette.fontFamily,
      whiteSpace: "nowrap",
      color: theme.palette.indexpage.cancelbtnPhoto,
      [theme.breakpoints.down("md")]: {
        fontSize: "12px",
      },
    },
  },
  largeRead: {
    "&.MuiTypography-root": {
      fontSize: "10px",
      fontFamily: theme.palette.fontFamily,
      whiteSpace: "nowrap",
    },
  },
  largeBtntxt1: {
    "&.MuiTypography-root": {
      fontSize: "14px",
      fontFamily: theme.palette.fontFamily,
      color: theme.palette.indexpage.cancelbtnCrl,
    },
  },
  largeBtntxtPhoto: {
    "&.MuiTypography-root": {
      fontSize: { xs: "14px", sm: "12px" },
      fontFamily: theme.palette.fontFamily,
      whiteSpace: "nowrap",
    },
  },
  largeBtntxtPhotoRemove: {
    "&.MuiTypography-root": {
      fontSize: { xs: "14px", sm: "12px" },
      fontFamily: theme.palette.fontFamily,
      whiteSpace: "nowrap",
      color: theme.palette.indexpage.cancelbtnPhoto,
      marginTop: "1px",
    },
  },
  cmnTxtfield: {
    "&.MuiFormControl-root": {
      input: {
        color: theme.palette.indexpage.main,
        fontSize: "12px",
        top: "4px",
      },
    },
    "& .MuiInputLabel-root": {
      color: theme.palette.indexpage.cmntxt1,
      fontSize: { xs: "16px", sm: "16px", lg: "12px" },
      opacity: "1",
    },
    "& .MuiInputBase-input.MuiOutlinedInput-input": {
      borderRadius: "0px !important",
      color: theme.palette.indexpage.main,
      backgroundColor: theme.palette.indexpage.background,
      height: "20px",
      fontSize: { xs: "16px", sm: "16px", lg: "12px" },
      opacity: "1",
      fontWeight: "500",
      textAlign: 'left !important',
    },
    "& .MuiOutlinedInput-root": {
      textAlign: 'left !important',
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderRadius: "4px",
      borderColor: theme.palette.indexpage.borderCrl,
    },
    "&.MuiFilledInput-root": {
      paddingTop: "0px",
    },
  },
  patientDetailBox: {
    "&.MuiBox-root": {
      backgroundColor: theme.palette.indexpage.modalcontent,
      borderRadius: "8px",
      boxShadow: `0px 3px 4px 0px ${theme.palette.indexpage.boxshdow}`,
      padding: "20px",
    },
  },
  patientDetailFillBox: {
    "&.MuiBox-root": {
      backgroundColor: theme.palette.background.paper,
      borderRadius: "8px",
      boxShadow: `0px 3px 4px 0px ${theme.palette.indexpage.boxshdow} `,
      padding: "25px",
    },
  },
  patientDetailFillBox3: {
    "&.MuiBox-root": {
      backgroundColor: theme.palette.indexpage.patientdetail,
      borderRadius: "8px",
      border: `1px solid ${theme.palette.indexpage.fileupload}`,
      padding: "20px",
    },
  },
  patientDetailFillBox2: {
    "&.MuiBox-root": {
      backgroundColor: theme.palette.background.paper,
      borderRadius: "8px",
      boxShadow: `0px 3px 4px 0px ${theme.palette.indexpage.boxshdow} `,
    },
  },
  patientDetailFillBox1: {
    "&.MuiBox-root": {
      backgroundColor: theme.palette.background.paper,
      borderRadius: "8px",
      boxShadow: `0px 3px 4px 0px ${theme.palette.indexpage.boxshdow} `,
      padding: "25px",
      marginTop: "15px",
    },
  },
  personCapstxt: {
    "&.MuiBox-root": {
      marginRight: "3px",
      backgroundColor: theme.palette.indexpage.personCaps,
      width: "40px",
      height: "40px",
      alignItems: "center",
      borderRadius: "50%",
      color: theme.palette.indexpage.personCaps1,
      fontSize: "20px",
      display: "flex",
      justifyContent: "center",
    },
  },
  personCapstxt1: {
    "&.MuiBox-root": {
      marginTop: "-2px",
      marginRight: "10px",
      width: "40px",
      height: "40px",
      alignItems: "center",
      fontSize: "20px",
      display: "flex",
      justifyContent: "center",
    },
  },
  persondataTxt: {
    "&.MuiTypography-root": {
      color: theme.palette.indexpage.statusColor,
      fontSize: "12px",
      fontWeight: "700",
      fontFamily: theme.palette.fontFamily,
    },
  },
  personDatas: {
    "&.MuiTypography-root": {
      color: theme.palette.indexpage.navbartxt,
      fontSize: "14px",
      fontWeight: "500",
      fontFamily: theme.palette.fontFamily,
      maxWidth: "100%",
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
  },
  personDataMainbox: {
    "&.MuiBox-root": {
      display: "flex",
      justifyContent: "space-between",
    },
  },
  patientDetails: {
    "&.MuiBox-root": {
      color: theme.palette.indexpage.navbartxt,
      fontSize: "14px",
      fontFamily: theme.palette.fontFamily,
      fontWeight: 700,
      textTransform: "uppercase",
    },
  },
  changePatientBox: {
    "&.MuiBox-root": {
      color: theme.palette.indexpage.viewbtn,
      fontSize: "12px",
      fontFamily: theme.palette.fontFamily,
    },
  },
  secondGrid: {
    "&.MuiGrid-root": {
      display: "flex",
      alignItems: "center",
    },
  },

  boxLeft: {
    "&.MuiBox-root": {
      backgroundColor: theme.palette.indexpage.boxwhite,
      boxShadow: "0px 8px 10px 0px rgba(0, 0, 0, 0.05)",
      borderRadius: "5px",
      padding: "20px",
    },
  },
  boxRight: {
    "&.MuiBox-root": {
      backgroundColor: theme.palette.indexpage.boxwhite,
      boxShadow: "0px 8px 10px 0px rgba(0, 0, 0, 0.05)",
      borderRadius: "5px",
      padding: "20px",
    },
  },
  fieldHead: {
    "&.MuiTypography-root": {
      fontSize: "15px",
      fontWeight: "500",
      fontFamily: theme.palette.fontFamily,
      color: theme.palette.indexpage.navbartxt,
    },
  },
  systemHead: {
    "&.MuiTypography-root": {
      fontSize: "20px",
      fontWeight: "500",
      fontFamily: theme.palette.fontFamily,
      color: theme.palette.indexpage.navbartxt,
    },
  },
  systemsubHead: {
    "&.MuiTypography-root": {
      marginTop: "4px",
      fontSize: "14px",
      fontWeight: "500",
      fontFamily: theme.palette.fontFamily,
      color: theme.palette.indexpage.navbartxt,
    },
  },

  mentalStatustxt: {
    "&.MuiTypography-root": {
      fontFamily: theme.palette.fontFamily,
      fontSize: "16px",
      fontWeight: 700,
      color: theme.palette.indexpage.navbartxt,
      [theme.breakpoints.up("sm")]: {
        whiteSpace: "nowrap",
      },
    },
  },

  backButtonBox: {
    "&.MuiBox-root": {
      marginBottom: "30px",
      display: "flex",
      alignItems: "center",
      color: theme.palette.indexpage.backbutton,
      cursor: "pointer",
      width: "fit-content",
    },
  },

  formLabelsx: {
    "&.MuiFormLabel-root": {
      color: theme.palette.indexpage.statusColor,
      fontSize: "12px",
      textTransform: "uppercase",
      fontWeight: 700,
    },
  },
  formLabelsx1: {
    "&.MuiFormLabel-root": {
      color: theme.palette.indexpage.statusColor,
      fontSize: "12px",
      textTransform: "uppercase",
      fontWeight: 700,
      whiteSpace: "nowrap",
    },
  },
  headerInputSec: {
    "&.MuiTypography-root": {
      marginTop: "3px",
      marginBottom: "10px",
      fontSize: "12px",
      fontFamily: theme.palette.fontFamily,
      color: theme.palette.indexpage.navbartxt,
      fontWeight: 700,
      textTransform: "uppercase",
    },
  },
  headerInputSecDoc: {
    "&.MuiTypography-root": {
      fontSize: "14px",
      fontFamily: theme.palette.fontFamily,
      color: theme.palette.indexpage.navbartxt,
      fontWeight: 700,
      textTransform: "uppercase",
      whiteSpace: "nowrap",
    },
  },
  headerInputSecDocnew: {
    "&.MuiTypography-root": {
      color: theme.palette.indexpage.statusColor,
      fontSize: "11px",
      fontFamily: theme.palette.fontFamily,
      fontWeight: 700,
      textTransform: "uppercase",
      whiteSpace: "nowrap",
    },
  },
  headerInputSecDoc1: {
    "&.MuiTypography-root": {
      fontSize: "14px",
      fontFamily: theme.palette.fontFamily,
      color: theme.palette.indexpage.headInputSecCrl,
      fontWeight: 700,
      textTransform: "uppercase",
      whiteSpace: "nowrap",
    },
  },
  containedButton: {
    "&.MuiButtonBase-root": {
      backgroundColor: theme.palette.indexpage.viewbtn,
      color: theme.palette.indexpage.boxwhite,
      fontSize: "14px",
      height: "32px",
      "&:hover": {
        backgroundColor: theme.palette.indexpage.viewbtn,
      },
    },
  },
  cancelOutlinebtn: {
    "&.MuiButtonBase-root": {
      backgroundColor: theme.palette.indexpage.boxwhite,
      color: theme.palette.indexpage.main,
      fontSize: "14px",
      height: "32px",
      whiteSpace: "noWrap",
    },
  },
  autoCompleteInput: {
    "&.MuiFormControl-root": {
      input: {
        color: theme.palette.indexpage.main,
        fontSize: "12px",
        top: "4px",
      },
    },
    "& .MuiInputLabel-root": {
      color: theme.palette.indexpage.cmntxt1,
      fontSize: "12px",
      opacity: "1",
    },
    "& .MuiInputBase-input.MuiOutlinedInput-input": {
      borderRadius: "0px !important",
      color: theme.palette.indexpage.main,
      backgroundColor: theme.palette.background.paper,
      height: "20px",
      fontSize: "12px",
      opacity: "1",
      fontWeight: "500",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderRadius: "4px",
      borderColor: theme.palette.indexpage.borderCrl,
    },
    "&.MuiFilledInput-root": {
      paddingTop: "0px",
    },
  },
  cmnAutoComplete: {
    "&.MuiAutocomplete-root .MuiOutlinedInput-root ": {
      height: "30px",
    },
  },
  visitBtnBox: {
    "&.MuiBox-root": {
      display: "flex",
      gap: 2,
      flexDirection: { xs: "column", sm: "row" },
      justifyContent: { xs: "flex-start", lg: "space-between" },
      "@media (max-width: 410px)": { flexDirection: "column" },
      "@media (min-width: 900px)": {
        justifyContent: "space-between",
        "& > :last-child": { marginLeft: "auto", marginRight: "auto" },
      },
    },
  },
  detailbox: {
    "&.MuiBox-root": {
      display: {
        xs: "flex",
        sm: "flex",
        md: "grid",
        lg: "flex",
        xl: "flex",
      },

      justifyContent: {
        xs: "center",
        sm: "center",
        md: "start",
        lg: "flex-start",
        xl: "flex-start",
      },
      alignItems: "center",
      flexDirection: {
        xs: "column",
        lg: "row",
      },
    },
  },
  pdfNameTxt: {
    "&.MuiTypography-root": {
      marginLeft: "3px",
      fontWeight: "700",
      color: theme.palette.indexpage.pdftxt,
      fontSize: "12px",
      marginTop: "5px",
    },
  },
  pdfNameTxt1: {
    "&.MuiTypography-root": {
      marginLeft: "1px",
      fontWeight: "700",
      color: theme.palette.indexpage.pdftxt,
      fontSize: "5px",
      marginTop: "-3px",
    },
  },
  pdfType: {
    "&.MuiBox-root": {
      display: "flex",
      paddingTop: "5px",
      paddingLeft: "10px",
      paddingBottom: "5px",
    },
  },
  pdfType1: {
    "&.MuiBox-root": {
      display: "flex",
      marginLeft: "2px",
    },
  },
  fingerIcon: {
    "&.MuiSvgIcon-root": {
      height: "15px",
    },
  },

  navArrowbackIcon: {
    "&.MuiSvgIcon-root": {
      color: theme.palette.indexpage.backbutton,
      fontSize: "18px",
      marginRight: "5px",
    },
  },
  navBackButton: {
    "&.MuiButton-root": {
      color: theme.palette.indexpage.backbutton,
      fontSize: "14px",
      backgroundColor: "transperant",
      border: "none",
    },
  },
  modalHead: {
    "&.MuiTypography-root": {
      fontSize: "16px",
      fontWeight: "700",
      marginTop: "-14px",
      fontFamily: theme.palette.fontFamily,
    },
  },
  statusBox2: {
    "&.MuiBox-root": {
      backgroundColor: theme.palette.indexpage.statsbox,
      border: `1px solid ${theme.palette.indexpage.statusboxCrl}`,
      borderRadius: "5px",
      width: "105px",
      height: "20px",
      color: theme.palette.indexpage.statusboxCrl,
      textAlign: "center",
      padding: "7px",
      background: "rgba(0, 148, 68, 0.05)",
      fontFamily: theme.palette.fontFamily,
      display: "flex",
      alignItems: "center",
    },
  },
  GridPadTopZero: { "&.MuiGrid-root": { paddingTop: "0px" } },

  validationMessage: {
    "&.MuiTypography-root": {
      color: "red",
    },
  },
  btnSubMainbox: {
    "&.MuiBox-root": {
      display: "flex",
      flexDirection: { xs: "column", sm: "row" },
      gap: { xs: 1, sm: 2 },
      alignItems: { xs: "stretch", sm: "center" },
      width: { xs: "100%", sm: "auto" },
    },
  },
  saveButtonPassport: {
    "&.MuiButtonBase-root": {
      backgroundColor: theme.palette.indexpage.viewbtn,
      color: theme.palette.background.paper,
      width: { xs: "100%", sm: "auto" },
      maxWidth: { xs: "100%", sm: "150px" },
      padding: { xs: "8px 16px", sm: "6px 12px" },
      "&:hover": {
        backgroundColor: theme.palette.indexpage.viewbtn,
      },
    },
  },
  largeBtntxtPassport: {
    "&.MuiTypography-root": {
      fontSize: { xs: "14px", sm: "12px" },
      fontFamily: theme.palette.fontFamily,
      whiteSpace: "nowrap",
    },
  },
  cancelButtonPassport: {
    "&.MuiButtonBase-root": {
      border: "1px solid",
      width: { xs: "100%", sm: "auto" },
      maxWidth: { xs: "100%", sm: "150px" },
      padding: { xs: "8px 16px", sm: "6px 12px" },
      borderColor: theme.palette.indexpage.cancelbtn,
      marginLeft: { xs: "0", sm: "10px" },
    },
  },
  btnMainbox: {
    "&.MuiBox-root": {
      display: "flex",
      flexDirection: { xs: "column", sm: "row" },
      justifyContent: "space-between",
      gap: 2,
    },
  },

  Datelayot: {
    "& .MuiInputBase-input": {
      fontWeight: 400,
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderRadius: "8px",
      },
    },
    "& .MuiInputLabel-root": {
      fontWeight: 600,
    },
    "& .MuiInputAdornment-root": {
      color: theme.palette.text.secondary,
    },
    "& .MuiInputAdornment-root .MuiSvgIcon-root": {
      color: theme.palette.text.secondary,
    },
  },
  Gridlabelpad: {
    "&.MuiGrid-root": {
      paddingTop: "15px",
    },
  },
  fingerPrintverifiedbox: {
    "&.MuiBox-root": {
      display: "flex",
      justifyContent: "space-between",
    },
  },
  fiberIcon: {
    "&.MuiSvgIcon-root": {
      height: "8px",
      width: "8px",
      marginRight: "2px",
    },
  },
  fingerVerifiedtxt: {
    "&.MuiTypography-root": {
      fontSize: "8px",
      whiteSpace: "nowrap",
    },
  },
  pdfImg: {
    height: "15px",
    width: "15px",
    marginTop: "-10px",
  },
  pdfImgPhoto: {
    height: "15px",
    width: "15px",
    marginTop: "-10px",
  },
  gridPhoto: {
    "&.MuiGrid-root": {
      marginTop: "20px ",
    },
  },
  deleteIcon: {
    "&.MuiSvgIcon-root": {
      height: "16px",
      width: "16px",
      color: theme.palette.indexpage.cancelbtnPhoto,
      marginRight: "2px",
    },
  },
  photoWarningtexxtbox: {
    "&.MuiBox-root": {
      display: "flex",
      marginTop: "5px",
    },
  },
  fiberIconPhoto: {
    "&.MuiSvgIcon-root": {
      height: "8px",
    },
  },
  fiberIconPhotoNew: {
    "&.MuiSvgIcon-root": {
      height: "8px",
      color: theme.palette.indexpage.cancelbtnPhoto,
    },
  },
  photoWarningTxt: {
    "&.MuiTypography-root": {
      marginTop: "-4px",
      fontSize: "12px",
    },
  },
  photoWarningTxtNew: {
    "&.MuiTypography-root": {
      marginTop: "-4px",
      fontSize: "12px",
      color: theme.palette.indexpage.cancelbtnPhoto,
    },
  },
  fileSize: {
    "&.MuiGrid-root": {
      marginTop: "10px",
    },
  },
  doubleArrow: {
    height: "18px",
    width: "18px",
  },
  passportSave: {
    "&.MuiBox-root": {
      display: "flex",
      justifyContent: "flex-end",
    },
  },
  photoviewBox: {
    "&.MuiBox-root": {
      display: "flex",
      justifyContent: "space-between",
    },
  },
  boxverified: {
    "&.MuiBox-root": {
      marginTop: "3px",
    },
  },
  boxFileview: {
    "&.MuiBox-root": {
      display: "flex",
    },
  },
  waringTxt: {
    "&.MuiTypography-root": {
      mb: 1,
      marginTop: "5px",
      marginLeft: "8px",
      fontSize: "10px",
    },
  },
  cropGrid: {
    "&.MuiGrid-root": {
      marginTop: "20px ",
    },
  },
  webCam: {
    width: "100%",
    objectFit: "contain",
    height: "100%",
  },
  boxFile: {
    "&.MuiBox-root": {
      display: "flex",
      justifyContent: "space-between",
    },
  },
  mainGridFile: {
    "&.MuiGrid-root": {
      display: "flex",
      alignItems: "end",
      justifyContent: "start",
    },
  },
  imageBoxfile: {
    "&.MuiBox-root": {
      width: "100%",
      overflow: "hidden",
      border: "1px solid #DCDFE4",
      borderRadius: "4px",
      height: "55px",
      position: "relative",
      cursor: "pointer",
    },
  },
  imageBoxsubFile: {
    "&.MuiBox-root": {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      position: "absolute",
      top: 0,
      left: 0,
      zIndex: 2,
      width: "100%",
      height: "100%",
      padding: "10px",
      boxSizing: "border-box",
    },
  },
  pdfFileImg: {
    height: "30px",
    width: "30px",
    marginBottom: "2px",
  },
  pdfFileBox: {
    "&.MuiBox-root": {
      width: "100%",
      height: "150px",
      transform: "scale(1.5)",
      transformOrigin: "top",
    },
  },
  iframeImg: {
    width: "100%",
    height: "100%",
  },
  blankBox: {
    "&.MuiBox-root": {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      cursor: "pointer",
      zIndex: 1,
    },
  },
  labelTxt: {
    width: "100%",
  },
  deleteIconfile: {
    "&.MuiSvgIcon-root": {
      height: "16px",
      width: "16px",
      color: "red",
      marginRight: "2px",
    },
  },
  closeIconfile: {
    "&.MuiSvgIcon-root": {
      height: "16px",
      width: "16px",
      color: "red",
      marginRight: "2px",
    },
  },
  errorMessageBox: {
    "&.MuiBox-root": {
      marginTop: "10px",
      marginLeft: "10px",
    },
  },
  errorMessageTxt: {
    "&.MuiTypography-root": {
      color: theme.palette.indexpage.cancelbtnPhoto,
      fontSize: "11px",
    },
  },
  spanFileCrl: {
    color: theme.palette.indexpage.cancelbtnPhoto,
  },
  saveFile: {
    "&.MuiBox-root": {
      display: "flex",
      justifyContent: "flex-end",
    },
  },
  boxPassportImg: {
    "&.MuiBox-root": {
      display: "flex",
    },
  },
  cropImagePass: {
    height: "18px",
    width: "18px",
  },
  passportData: {
    "&.MuiGrid-root": {
      marginTop: "5px",
    },
  },
  passportSaveGrid: {
    "&.MuiGrid-root": {
      marginTop: "20px",
    },
  },
  PassportSaveBox: {
    "&.MuiBox-root": {
      display: "flex",
      justifyContent: "flex-end",
    },
  },
});

export default styles;
