import { lazy } from 'react';
import { Navigate } from 'react-router-dom';
import Loadable from 'ui-component/Loadable';
import MainLayout from 'layout/MainLayout';

const Profile = Loadable(lazy(() => import('screens/Profile/index')));

const ProfileRouter = {
  path: '/',
  element: (
    <>
        <MainLayout />
    </>
  ),
  children: [
    {
      path: '',
      element: <Navigate to="/profile" replace={true} />
    },
    {
      path: '/profile',
      element: <Profile />
    },
  ],
};

export default ProfileRouter;
