import React from "react";
import { lazy } from "react";
import Loadable from "ui-component/Loadable";
import MinimalLayout from "layout/MinimalLayout";
import { Navigate } from "react-router-dom";

const Login = Loadable(lazy(() => import("screens/login/index")));

const AuthenticationRoutes = {
  element: <MinimalLayout />,
  children: [
    {
      path: "/login",
      element: <Login />,
    },
    {
      path: "",
      element: <Navigate to="/login" replace={true} />,
    },
  ],
};

export default AuthenticationRoutes;
