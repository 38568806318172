import { Typography } from '@mui/material';
import PropTypes from 'prop-types';
import menuItem from 'menu-items';
import NavGroup from './NavGroup';

// ==============================|| SIDEBAR MENU LIST ||============================== //

const MenuList = ({ drawerToggle }) => {

  const navItems = menuItem?.admin?.map((item) => {
    switch (item.type) {
      case 'group':
        return <NavGroup key={item.id} item={item} drawerToggle={drawerToggle} />;
      default:
        return (
          <Typography key={item.id} variant="h6" color="error" align="center">
            Menu Items Error
          </Typography>
        );
    }
  });

  return <>{navItems}</>;
};

MenuList.propTypes = {
  drawerOpen: PropTypes.bool
};

export default MenuList;