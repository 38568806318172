import React from "react";
import PropTypes from "prop-types";
import { useTheme } from "@mui/material/styles";
import { Avatar, Box,  ButtonBase, Grid } from "@mui/material";
import LogoSection from "../LogoSection";
import ProfileSection from "./ProfileSection";
import UserSection from "./UserSection";
import { IconMenu2 } from "@tabler/icons";

// ==============================|| MAIN NAVBAR / HEADER ||============================== //

const Header = ({ handleLeftDrawerToggle }) => {
  const theme = useTheme();

  return (
    <>
      {/* logo & toggler button */}
      <Box
        sx={{
          width: 278,
          display: "flex",
          [theme.breakpoints.down("md")]: {
            width: "auto",
          },
        }}
      >
        <Box
          component="span"
          sx={{ display: { xs: "none", md: "block" }, flexGrow: 1 }}
        >
          <LogoSection />
        </Box>
        <ButtonBase onClick={handleLeftDrawerToggle}
          sx={{ borderRadius: "12px", overflow: "hidden" , marginLeft:'10px' }}
        >
          <Avatar
            variant="rounded"
            sx={{
              ...theme.typography.commonAvatar,
              ...theme.typography.mediumAvatar,
              transition: "all .2s ease-in-out",
              background: theme.palette.secondary.light,
              color: theme.palette.secondary.dark,
              "&:hover": {
                background: theme.palette.secondary.dark,
                color: theme.palette.secondary.light,
              },
            }}
            color="inherit"
          >
            <IconMenu2 stroke={1.5} size="1.3rem" />
          </Avatar>
        </ButtonBase>
      </Box>

      {/* notification & profile */}
      <Grid
        container
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Grid item xs={6} sm={10}>
          <UserSection />
        </Grid>

        <Grid
          item
          xs={6}
          sm={2}
          style={{ display: "flex", justifyContent: "flex-end" }}
        >
          <ProfileSection />
        </Grid>
      </Grid>
    </>
  );
};

Header.propTypes = {
  handleLeftDrawerToggle: PropTypes.func,
};

export default Header;
