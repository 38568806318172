import { combineReducers } from 'redux';
import customizationReducer from './customizationReducer';
import loginReducer from 'container/LoginContainer/slice';

// ==============================|| COMBINE REDUCER ||============================== //

const reducer = combineReducers({
  customization: customizationReducer,
  login: loginReducer
});

export default reducer;
