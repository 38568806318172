// import { takeEvery, call, put } from 'redux-saga/effects';
// import { toast } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';
// import auth from 'container/auth';
// import config from 'config';
// import {
//   loginSuccess, loginFail, loginUserSuccess, loginUser, loginUserFail,
//   sendOtpRequestSuccess, sendOtpRequestFail,
//   checkOtpRequestSuccess, checkOtpRequestFail,
//   changePasswordRequestSuccess, changePasswordRequestFail
// } from './slice';

// function* login(action) {
//   console.log('======action======', action.payload);

//   try {
//     const response = yield fetch(`${config.Ip}/auth`, {
//       method: 'POST',
//       headers: {
//         Accept: 'application/json',
//         'Content-Type': 'application/json',
//         Authorization: 'Basic ' + btoa(`${action.payload.mobileNo}:${action.payload.password}`)
//       }
//     });


//     if (!response.ok) {

//       yield toast.error('Invalid User', {
//         autoClose: 5000,
//       });
//       throw response;

//     } else {
//       const resJSON = yield response.json();
//       console.log('===============resJSON=====================', resJSON);
//       yield localStorage.setItem(process.env.REACT_APP_TOKEN, resJSON.token);
//       yield put(loginSuccess(resJSON));
//       yield put(loginUser(action.payload));

//     }
//   } catch (error) {

//     // console.error('error', error);
//     // yield put(loginFail('Login failed. Please try again.'));
//     // yield toast.error('Invalid User', {
//     //   autoClose: 3000
//     // });

//     console.error(' error.message', error.message);
//     if (error.status && error.message) {
//       yield put(loginFail(error.message));
//       yield toast.error(error.message, {
//         autoClose: 3000
//       });
//     }
//   }
// }

// function* loginUserDetail(action) {
//   try {
//     let params = {
//       api: `${config.Ip}/users/me`,
//       method: 'GET',
//       successAction: loginUserSuccess(),
//       failAction: loginUserFail('Login failed. Please try again.'),
//       authourization: 'token'
//     };

//     let res = yield call(auth.basicApi, params);

//     if (res && [ 'admin','vendor', 'licensee','adminAcnt','adminMngr' ,'adminOptr',
//     'vendorAcnt', 'vendorMngr','vendorOptr','licnseAcnt', 'licnseMngr','licnseOptr'].includes(res.role)) {
//       let user = {
//         user: res
//       };
//       console.log('======res.role==========', res.role);
//       yield localStorage.setItem(process.env.REACT_APP_LOGINUSER, JSON.stringify(user));

//       // Navigate to different dashboards based on user roles
//       switch (res.role) {
//         case 'admin':
//           action.payload.navigate('/admin-dashboard');
//           break;
//         case 'licensee':
//           action.payload.navigate('/licensee-dashboard');
//           break;
//         case 'vendor':
//           action.payload.navigate('/dashboard');
//           break;
//         case 'adminAcnt':
//           action.payload.navigate('/admin-dashboard');
//           break;
//         case 'adminMngr':
//           action.payload.navigate('/admin-dashboard');
//           break;
//         case 'adminOptr':
//           action.payload.navigate('/admin-dashboard');
//           break;
//         case 'licnseAcnt':
//           action.payload.navigate('/licensee-dashboard');
//           break;
//         case 'licnseMngr':
//           action.payload.navigate('/licensee-dashboard');
//           break;
//         case 'licnseOptr':
//           action.payload.navigate('/licensee-dashboard');
//           break;
//         case 'vendorAcnt':
//           action.payload.navigate('/dashboard');
//           break;
//         case 'vendorMngr':
//           action.payload.navigate('/dashboard');
//           break;
//         case 'vendorOptr':
//           action.payload.navigate('/dashboard');
//           break;

//         // default:
//         //   action.payload.navigate('/dashboard');
//       }

//       console.log('======res.role==========', res.role);
//       yield toast.success('Login successfully', {
//         autoClose: 3000
//       });
//     }
//     //  else {
//     //   yield toast.error('Invalid User', {
//     //     autoClose: 3000
//     //   });
//     // }
//   } catch (error) {

//     console.error(' error.message', error.message);
//     if (error.status && error.message) {
//       yield toast.error(error.message, {
//         autoClose: 3000
//       });
//     }
//     // yield toast.error('Invalid User', {
//     //   autoClose: 3000
//     // });

//     //  console.log(erorrr ,"============saba====erorrr================");
//   }
// }

// function* sendOtpRequest(action) {
//   const data = action.payload;
//   try {
//     let params = {
//       api: `${config.Ip}/passwordReset/sendotp`,
//       method: 'POST',
//       successAction: sendOtpRequestSuccess(),
//       failAction: sendOtpRequestFail(),
//       authourization: 'token',
//       body: JSON.stringify(data)
//     };
//     let res = yield call(auth.basicApi, params);
//     if (res) {
//       yield toast.success('An OTP has been sent to the registered mobile number', {
//         autoClose: 3000
//       });
//       yield action.payload.navigate('/EnterOTP');
//     } else {
//       yield toast.error('User not found!! Try again with the registered mobile number', {
//         autoClose: 3000
//       });
//     }
//   } catch (error) {
//     console.log(error);
//   }
// }

// function* checkOtpRequest(action) {
//   const data = {
//     mobileNo: action.payload.data,
//     pin: action.payload.otp
//   };
//   try {
//     let params = {
//       api: `${config.Ip}/passwordReset/checkpin`,
//       method: 'POST',
//       successAction: checkOtpRequestSuccess(),
//       failAction: checkOtpRequestFail(),
//       authourization: 'token',
//       body: JSON.stringify(data)
//     };
//     let res = yield call(auth.basicApi, params);

//     if (!res.ok) {
//       yield toast.error("You have entered a wrong OTP.", {
//         autoClose: 3000,
//       });
//     } else {
//       yield toast.success("OTP Verified", {
//         autoClose: 3000
//       });
//       action.payload.navigate('/NewPassword');
//     }
//   } catch (error) {
//     if (error.status === 404) {
//       yield toast.error('Redirecting to login page, since you have used your maximum attempts', {
//         autoClose: 5000,
//       });
//     }
//     return error;
//   }
// }

// function* changePasswordRequest(action) {
//   const data = {
//     password: action.payload.newPassword,
//     mobileNo: action.payload.userData.user.mobileNo,
//     pin: action.payload.userData.pin
//   };
//   try {
//     let params = {
//       api: `${config.Ip}/passwordReset/change`,
//       method: 'PUT',
//       successAction: changePasswordRequestSuccess(),
//       failAction: changePasswordRequestFail(),
//       authourization: 'token',
//       body: JSON.stringify(data)
//     };

//     yield call(auth.basicApi, params);
//     yield call(() => toast.success('Password Updated successfully', { autoClose: 3000 }));
//   } catch (error) {
//     console.log(error);
//   }
// }

// export default function* LoginActionWatcher() {
//   yield takeEvery('login/userLogin', login);
//   yield takeEvery('login/loginUser', loginUserDetail);
//   yield takeEvery('login/SendOtpRequest', sendOtpRequest);
//   yield takeEvery('login/checkOtpRequest', checkOtpRequest);
//   yield takeEvery('login/changePasswordRequest', changePasswordRequest);
// }




import { takeEvery, call, put } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import auth from 'container/auth';
import config from 'config';


import {
  loginSuccess, loginFail, loginUserSuccess, loginUser, loginUserFail,
  sendOtpRequestSuccess, sendOtpRequestFail,
  checkOtpRequestSuccess, checkOtpRequestFail,
  changePasswordRequestSuccess, changePasswordRequestFail
} from './slice';

function* login(action) {
  console.log('======action======', action.payload);

  try {
    const response = yield fetch(`${config.Ip}/auth`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Basic ' + btoa(`${action.payload.mobileNo}:${action.payload.password}`)
      }
    });


    if (!response.ok) {

      yield toast.error('Invalid User', {
        autoClose: 5000,
      });
      throw response;

    } else {
      const resJSON = yield response.json();
      console.log('===============resJSON=====================', resJSON);
      yield localStorage.setItem(process.env.REACT_APP_TOKEN, resJSON.token);
      yield put(loginSuccess(resJSON));
      yield put(loginUser(action.payload));


    }
  } catch (error) {



    if (error.status && error.message) {
      yield put(loginFail(error.message));
      yield toast.error(error.message, {
        autoClose: 3000
      });
    }
  }
}




function* loginUserDetail(action) {
  console.log("Action.playlodellll",action.payload);
  
  try {
    let params = {
      api: `${config.Ip}/users/me`,
      method: 'GET',
      successAction: loginUserSuccess(),
      failAction: loginUserFail('Login failed. Please try again.'),
      authourization: 'token'
    };

    let res = yield call(auth.basicApi, params);

    if (res && ['admin', 'vendor', 'licensee', 'adminAcnt', 'adminMngr', 'adminOptr',
      'vendorAcnt', 'vendorMngr', 'vendorOptr', 'licnseAcnt', 'licnseMngr', 'licnseOptr'].includes(res.role)) {
      let user = {
        user: res
      };
      console.log('======res.role==========', res.role);
      yield localStorage.setItem(process.env.REACT_APP_LOGINUSER, JSON.stringify(user));

      // Navigate to different dashboards based on user roles
      switch (res?.role) {
        case 'admin':
        case 'adminAcnt':
        case 'adminMngr':
        case 'adminOptr':

          yield action.payload.navigate('/admin-dashboard');

          break;
        case 'licensee':
        case 'licnseAcnt':
        case 'licnseMngr':
        case 'licnseOptr':
          yield action.payload.navigate('/licensee-dashboard');

          break;
        case 'vendor':
        case 'vendorAcnt':
        case 'vendorMngr':
        case 'vendorOptr':
          yield action.payload.navigate('/dashboard');
          break;
        // default:
        // action.payload.navigate('/dashboard');
      }
      yield put(loginUser({ role: res.role }));

   

      yield toast.success('Login successfully', {
        autoClose: 3000
      });
    }
  } catch (error) {
    // console.error(' error.message', error.message);
    if (error.status && error.message) {
      yield toast.error(error.message, {
        autoClose: 3000
      });
    }
  }
}


function* sendOtpRequest(action) {
  const data = action.payload;
  try {
    let params = {
      api: `${config.Ip}/passwordReset/sendotp`,
      method: 'POST',
      successAction: sendOtpRequestSuccess(),
      failAction: sendOtpRequestFail(),
      authourization: 'token',
      body: JSON.stringify(data)
    };
    let res = yield call(auth.basicApi, params);
    if (res) {
      yield toast.success('An OTP has been sent to the registered mobile number', {
        autoClose: 3000
      });
      yield action.payload.navigate('/EnterOTP');
    } else {
      yield toast.error('User not found!! Try again with the registered mobile number', {
        autoClose: 3000
      });
    }
  } catch (error) {
    console.log(error);
  }
}

function* checkOtpRequest(action) {
  const data = {
    mobileNo: action.payload.data,
    pin: action.payload.otp
  };
  try {
    let params = {
      api: `${config.Ip}/passwordReset/checkpin`,
      method: 'POST',
      successAction: checkOtpRequestSuccess(),
      failAction: checkOtpRequestFail(),
      authourization: 'token',
      body: JSON.stringify(data)
    };
    let res = yield call(auth.basicApi, params);

    if (!res.ok) {
      yield toast.error("You have entered a wrong OTP.", {
        autoClose: 3000,
      });
    } else {
      yield toast.success("OTP Verified", {
        autoClose: 3000
      });
      action.payload.navigate('/NewPassword');
    }
  } catch (error) {
    if (error.status === 404) {
      yield toast.error('Redirecting to login page, since you have used your maximum attempts', {
        autoClose: 5000,
      });
    }
    return error;
  }
}

function* changePasswordRequest(action) {
  const data = {
    password: action.payload.newPassword,
    mobileNo: action.payload.userData.user.mobileNo,
    pin: action.payload.userData.pin
  };
  try {
    let params = {
      api: `${config.Ip}/passwordReset/change`,
      method: 'PUT',
      successAction: changePasswordRequestSuccess(),
      failAction: changePasswordRequestFail(),
      authourization: 'token',
      body: JSON.stringify(data)
    };

    yield call(auth.basicApi, params);
    yield call(() => toast.success('Password Updated successfully', { autoClose: 3000 }));
  } catch (error) {
    console.log(error);
  }
}

export default function* LoginActionWatcher() {
  yield takeEvery('login/userLogin', login);
  yield takeEvery('login/loginUser', loginUserDetail);
  yield takeEvery('login/SendOtpRequest', sendOtpRequest);
  yield takeEvery('login/checkOtpRequest', checkOtpRequest);
  yield takeEvery('login/changePasswordRequest', changePasswordRequest);
}
